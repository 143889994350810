//import Vue from 'vue'
//import VueRouter from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router';


import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Login from '../views/Login.vue'
import Orgs from '../views/Orgs.vue'
import Users from '../views/Users.vue'
import DocuProject from '../views/DocuProject.vue'
import Evidence from '../views/EvProject.vue'
import Projects from '../views/Projects.vue'
import ProjectDashboard from '../views/ProjectDashboard.vue'
import Inventory from '../views/Inventory.vue'
import Profile from '../views/Profile.vue'
import Auth from '../views/Auth.vue'
import Mfa from '../views/Mfa.vue'
import Logs from '../views/Logs.vue'
import Test from '../views/Test.vue'
//Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Projects,
    meta: {
      title: 'Projects'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: 'About'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Log In'
    }
  },
  {
    path: '/orgs',
    name: 'Orgs',
    component: Orgs,
    meta: {
      title: 'Organizations'
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      title: 'Users'
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: {
      title: 'Projects'
    }
  },
  {
    path: '/docuproject',
    name: 'DocuProject',
    component: DocuProject,
    meta: {
      title: 'Document Project'
    }
  },
  {
    path: '/projectdashboard',
    name: 'ProjectDashboard',
    component: ProjectDashboard,
    meta: {
      title: 'Project Dashboard'
    }
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: Inventory,
    meta: {
      title: 'Inventory'
    }
  },
  {
    path: '/evidence',
    name: 'Evidence',
    component: Evidence,
    meta: {
      title: 'Evidence'
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      title: 'Profile'
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    meta: {
      title: 'Auth'
    }
  },
  {
    path: '/mfa',
    name: 'Mfa',
    component: Mfa,
    meta: {
      title: 'MFA'
    }
  },
  {
    path: '/logs',
    name: 'Logs',
    component: Logs,
    meta: {
      title: 'Logs'
    }
  },
  {
    path: '/test',
    name: 'Test',
    component: Test,
    meta: {
      title: 'Test'
    }
  }    
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  //if ('getters' in router.app.$store && router.app.$store.getters.enforceMfa == true){console.warn("enforce redirect!")}
  //if (router.app.$store && router.app.$store.getters.enforceMfa == true) { console.warn("enforcemfa") }
  document.title = "Vantage - " + to.meta.title;
  next();
})

export default router

