<template>
    <div>        

      <v-navigation-drawer ref="drawer" location="right" :width="navigation.width" id="rightDrawer" color="#b5b5b5" class="pl-1" v-model="localShowHide" >
          <div class="resize-border" ref="border"></div>
          <v-tabs class="fixed-tabs-bar" slider-color="green" v-model="tab" fixed-tabs bg-color="#3d4856" theme="dark" height="40">                  
                <v-tab value="evidence">
                Evidence
                </v-tab>
                <v-tab value="chat">
                Chat
                </v-tab>
            </v-tabs>

                  <div v-show="tab != 'chat'" style="background-color: #233a62; display: flex; flex-wrap: nowrap; align-items: center; height: 64px;" class="pt-2;">

                  <tooltip position="bottom-right" text="Show illustrative procedures">
                      <v-icon :color="illpolcolor" @click="evShowCards.showpip = !evShowCards.showpip" class="ml-5">
                      mdi-book-open-outline
                      </v-icon>
                  </tooltip>
                  &nbsp;&nbsp;
                  <tooltip position="bottom-right" text="Show gaps">
                      <v-icon :color="gapscolor" @click="evShowCards.showgaps = !evShowCards.showgaps">
                      mdi-alert
                      </v-icon>
                  </tooltip>
                  &nbsp;&nbsp;
                  <tooltip position="bottom-right" text="Show test results">
                      <v-icon  :color="tresultscolor" @click="evShowCards.showtresults = !evShowCards.showtresults">
                      mdi-view-list
                      </v-icon>
                  </tooltip>
                  &nbsp;&nbsp;
                  <tooltip position="bottom-right" text="Show options">
                      <v-icon :color="optionscolor" @click="evShowCards.showoptions = !evShowCards.showoptions">                       
                      mdi-tune
                      </v-icon>
                  </tooltip>
                  <v-spacer></v-spacer>
                    <v-chip variant="elevated" elevation="4" color="#3d4856" label class="ma-2" ><span class="text-caption"> {{ currentTopic }} </span></v-chip>
                    <v-chip variant="elevated" elevation="4" color="#d3d3d3" label class="ma-2" @click="test()"><span class="text-caption"> {{ currentObj.id }} </span></v-chip>
                  </div>                     

            <div v-if="this.sideLoading" class="pl-4 pr-4">
              <v-overlay opacity="0.75" model-value class="align-center justify-center" contained>
                <v-progress-circular color="primary" indeterminate :size="120">
                  Loading...
                </v-progress-circular>
              </v-overlay>
            </div>

            <v-window v-else v-model="tab" style="background-color: transparent">
              <!-- AI TAB -->
                <v-window-item style="overflow: auto; height: calc(100vh - 213px);" class="mt-0" value="evidence">

            
                        <v-container class="py-5">
                        <v-expansion-panels v-model="sampPanels" multiple style="width: 100%">
                        <draggable v-model="sampCards" item-key="id" handle=".handle" style="width: 100%">
                        <template #item="{element}">
              

                        <v-row>

                          <!-- REQUEST -->

                            <v-col md="12" class="pa-1" v-if="element.id == 1">


                                <v-expansion-panel bg-color="#0969ab87" value="REQ" elevation="5">
                                  <v-expansion-panel-title class="pa-2">
                                    <span class="text-button text-white"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Requirement</span>
                                  </v-expansion-panel-title>
                                  <v-expansion-panel-text class="expansion-text">
                                    {{ currentObj.description }}
                                  </v-expansion-panel-text>
                                </v-expansion-panel>


                            </v-col>


                            <!-- GUIDANCE -->

                            <v-col md="12" class="pa-1" v-if="element.id == 2 && evShowCards.showpip">

                              <v-expansion-panel bg-color="#3d9e3dcf" value="GUID" elevation="5">
                                  <v-expansion-panel-title class="pa-2">
                                    <span class="text-button text-white"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Guidance</span>
                                  </v-expansion-panel-title>
                                  <v-expansion-panel-text class="expansion-text">
                                    {{ currentObj.request }}
                                  </v-expansion-panel-text>
                                </v-expansion-panel>

                            </v-col> 

                            <!-- AI EVIDENCE -->
                            
                            <v-col md="12" class="pa-1" v-if="element.id == 3 && this.currentObj.type == 'AI'">
                              <v-expansion-panel bg-color="#ffffffcf" value="SAMP" elevation="5">
                                <v-expansion-panel-title class="pa-2">
                                  <span class="text-button text-black"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Samples</span>
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                  <span class="expansion-text-dark expansion-pad">Upload the approriate evidence request for each sample.</span>

                                  <div class="sampleTables">

                                  <v-tabs class="overscroll" id="scrollstyle" direction="vertical" v-model="samplestab" background-color="transparent" slider-color="primary" slider-size="5" style="max-height: 675px;">
                                    <v-tab @drop="drop($event, item)" @dragover.prevent @dragenter="tabDragOn(item)" @dragleave="tabDragOff(item)" @dragend="tabDragOff(item)" @click="sampleTabSelected(item)" :id="item.id" class="text-overline font-weight-regular" :class="{ 'tab-fail': item.score == 0, 'tab-normal': item.score == 1, 'tab-pass': item.score == 2, 'tab-na': item.na == true }" :style="[item.id == tabdragging ? {'border-style' : 'solid', 'border-color' : '#00db00'} : '']" v-for="(item,index) in currentSamples" :key="'tab-samples-' + index" >
                                      {{ item.name }}
                                      <v-spacer></v-spacer>&nbsp;                                              
                                      <v-icon small class="pl-2" v-if="item.complete == true && item.assessorcomplete != true" color="primary" >mdi-checkbox-marked-circle-outline</v-icon>
                                      <v-icon small class="pl-2" v-if="item.assessorcomplete == true" color="primary" >mdi-checkbox-marked-circle</v-icon>
                                    </v-tab>                                          
                                    </v-tabs>


                                    <v-window id="tabItems" v-model="samplestab" style="border-style: solid; border-width: thin; border-color: #bcbcbc; width: 100%">
                                      <v-window-item v-for="(item,index) in currentSamples" :key="'tab-items-samples-' + index"  >
                                        <v-card flat @drop="drop($event, item)" @dragover.prevent @dragenter="cardDragOn()" @dragleave="cardDragOff()" @dragend="cardDragOff()">
                                          <v-card-title class="text-button">{{ item.name }}</v-card-title>
                                          <v-card-subtitle class="d-flex justify-space-between">
                                          Drag and drop files here to add to evidence.
                                          <!-- Comment about this sample. -->

                                                    <v-icon color="primary" @click="tab = 1">mdi-chat-question</v-icon>

                                          </v-card-subtitle>
                                          <v-card-text style="max-height: 700px">

                                            <v-data-table
                                              v-if="$store.getters.allEvGetter != null"                                                    
                                              dense                                                                                                     
                                              fixed-header
                                              key="aitable"
                                              :headers="fileListHeaders"
                                              :height="sampleEvidenceCompute.length > 8 ? 32 * 10 : undefined"
                                              :items="sampleEvidenceCompute"
                                              :items-per-page="-1"
                                              :item-class="itemRowBackground"
                                              :loading="tableLoading"
                                              loading-text="Loading... Please wait"                                                      
                                              hide-default-footer
                                              class="elevation-1 datatable"
                                            >                                               

                                              <template v-slot:item.comment="{ item }">
                                                <div style="display: flex; flex-wrap: wrap; cursor: pointer;" @click="evidenceComment(item)">
                                                  <span class="text-caption" id="listComment">{{ item.comment }}</span><span class="primary--text">...</span>                                            
                                                </div>
                                              </template>

                                              <template v-slot:item.name="{ item }">
                                                <a :href="item.url" target="_blank">{{ item.name }}</a>
                                              </template>

                                              <template v-slot:item.actions="{ item }">
                                                <!-- Delete evidence  v-if="searchEvRay(item) != true" -->
                                          
                                                      <v-icon v-if="searchEvRay(item) != true" size="small" @click="remEvAssociation(item)">mdi-delete</v-icon>

                                                &nbsp;
                                                <!-- Evidence description v-if="searchEvRay(item) != true" -->
                                                                                                        
                                                      <v-icon v-if="searchEvRay(item) != true" size="small" @click="evidenceComment(item)">mdi-text</v-icon>
                                              
                                              </template>

                                              <template v-slot:bottom> </template>

                                            </v-data-table>
                                        <v-container v-if="'score' in item">

                                        <v-row >

                                          <v-col v-if="item.na == false" :cols="parseInt(navigation.width) < 800 ? '12' : '3'" align="left" class="pt-4 mt-2">
                                            <v-btn-toggle variant="outlined" density="compact" v-model="item.score" mandatory class="pr-2">
                                              <!-- Not Compliant -->                                                   
                                                  <v-btn size="small">
                                                    <v-icon color="red">mdi-close-outline</v-icon>
                                                  </v-btn>                          

                                                <!-- Unscored -->                                                    
                                                <v-btn size="small">
                                                    <v-icon color="primary">mdi-minus-circle-outline</v-icon>
                                                  </v-btn>
                                                <!-- Fully Compliant -->
                                      
                                                <v-btn size="small">
                                                      <v-icon color="green">mdi-check</v-icon>
                                                    </v-btn>

                                            </v-btn-toggle>                                                    
                                          </v-col>    
                                          <v-col :cols="parseInt(navigation.width) < 800 ? '12' : '9'" align="right" class="pt-4">
                                            <div style="display: flex; flex-wrap: wrap; justify-content: right;">
                                              <v-chip size="small" class="mt-2" :color="item.complete ? 'primary' : 'null' " :input-value="item.complete" @click="item.complete = !item.complete" filter outlined>Complete</v-chip>
                                              <v-chip size="small" class="mt-2 ml-1" :color="item.assessorcomplete ? 'primary' : 'null' " :input-value="item.assessorcomplete" @click="item.assessorcomplete = !item.assessorcomplete" filter outlined v-if="item.complete == true">Assessor Complete</v-chip>
                                              <v-chip size="small" class="mt-2 ml-1 mr-2" :color="item.na ? 'red' : 'null' " :input-value="item.na" @click="item.na = !item.na" filter outlined>Not Applicable</v-chip>
                                              <v-btn @click="saveEv()" :loading="saving" :disabled="saving" class="no-active" icon color="primary" variant="text">
                                                  <v-icon>mdi-content-save</v-icon>
                                              </v-btn>                                                        
                                            </div>
                                          </v-col> 

                                        </v-row>       
                                        <v-row>
                                          <v-col cols="12" v-if="item.score == 0">
                                            <v-text-field density="compact" hide-details label="Comment about sample gaps..." v-model="item.comment" variant="outlined" color="red" style="font-size: 14px"></v-text-field>
                                          </v-col>
                                        </v-row>
                                        </v-container>

                                          </v-card-text>
                                        </v-card>
                                      </v-window-item>
                                    </v-window>
                                  </div>
                                </v-expansion-panel-text>
                              </v-expansion-panel>
                            </v-col>

                            <!-- ER EVIDENCE -->

                            <v-col md="12" class="pa-1" v-if="element.id == 4 && this.currentObj.type != 'AI'"  id="ERCard">
                              <v-expansion-panel bg-color="#ffffffcf" value="EVID" elevation="5" id="tabItems" color="#ffffffcf" max-height="1000" @drop="drop($event)" @dragover.prevent @dragenter="cardDragOn()" @dragleave="cardDragOff()" @dragend="cardDragOff()">
                                <v-expansion-panel-title class="pa-2">
                                  <span class="text-button text-black"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Evidence</span>
                                </v-expansion-panel-title>
                                <v-expansion-panel-text style="max-height: 600px">                                  
                                  <span class="expansion-text-dark expansion-pad">Drag and drop files here to add to upload the approriate evidence for the stated request.</span>
                                    <v-data-table      
                                        v-if="$store.getters.allEvGetter != null"                                            
                                        dense                                                                                                     
                                        fixed-header
                                        key="ertable"
                                        :height="sampleEvidenceCompute.length > 8 ? 32 * 10 : undefined"
                                        :headers="fileListHeaders"
                                        :items="sampleEvidenceCompute"
                                        :items-per-page="-1"
                                        :loading="tableLoading"
                                        loading-text="Loading... Please wait"                                        
                                        hide-default-footer
                                        class="elevation-1 datatable"
                                      >

                                        <template v-slot:item.name="{ item }">
                                          <a :href="item.url" target="_blank">{{ item.name }}</a>
                                        </template>

                                        <template v-slot:item.comment="{ item }">
                                          <div style="display: flex; flex-wrap: wrap; cursor: pointer;" @click="evidenceComment(item)">
                                            <span class="text-caption" id="listComment">{{ item.comment }}</span><span class="primary--text">...</span>                                            
                                          </div>
                                        </template>                                        

                                        <template v-slot:item.actions="{ item }">
                                          <!-- Delete evidence -->
                                              
                                                  <v-icon small @click="remEvAssociation(item)">mdi-delete</v-icon>

                                          &nbsp;
                                          <!-- Evidence description -->
                                                                                                             
                                                <v-icon small @click="evidenceComment(item)">mdi-text</v-icon>
                                                  
                                        </template>

                                        <template v-slot:bottom> </template>

                                      </v-data-table>
                                    <v-container>
                                    <v-row >
                                      <v-col v-if="currentObj.na == false || !('na' in currentObj)" :cols="parseInt(navigation.width) < 800 ? '12' : '3'" align="left" class="pt-4 mt-1">
                                        <v-btn-toggle v-model="currentObj.pass" mandatory variant="outlined" density="compact">
                                          <!-- Not Compliant -->
                                               
                                                <v-btn size="small">
                                                  <v-icon color="red">mdi-close-outline</v-icon>
                                                </v-btn>
  
                                          <!-- Unscored                                                -->
                                                 
                                                <v-btn size="small" >
                                                  <v-icon color="primary">mdi-minus-circle-outline</v-icon>
                                                </v-btn>

                                          <!-- Fully Compliant -->                                                
                                                <v-btn size="small">
                                                  <v-icon color="green">mdi-check</v-icon>
                                                </v-btn>

                                        </v-btn-toggle>                                                    
                                      </v-col>    
                                      <v-col :cols="parseInt(navigation.width) < 800 ? '12' : '9'" align="right" class="pt-4">
                                        <div style="display: flex; flex-wrap: wrap; justify-content: right;">
                                          <v-chip size="small" class="mt-2" :color="currentObj.clientcomplete ? 'primary' : 'null' " :input-value="currentObj.clientcomplete" @click="currentObj.clientcomplete = !currentObj.clientcomplete" filter outlined>Complete</v-chip>
                                          <v-chip size="small" class="mt-2 ml-1" :color="currentObj.assessorcomplete ? 'primary' : 'null' " :input-value="currentObj.assessorcomplete" @click="currentObj.assessorcomplete = !currentObj.assessorcomplete" filter outlined v-if="currentObj.clientcomplete == true">Assessor Complete</v-chip>
                                          <v-chip size="small" class="mt-2 ml-1 mr-2" :color="currentObj.na ? 'red' : 'null' " :input-value="currentObj.na" @click="currentObj.na = !currentObj.na" filter outlined>Not Applicable</v-chip>                                        
                                          <v-btn @click="saveEv()" :loading="saving" :disabled="saving" class="no-active" icon color="primary" variant="text">
                                              <v-icon>mdi-content-save</v-icon>
                                          </v-btn>                                                           
                                        </div>
                                      </v-col>
                                                                                                                                 
                                    </v-row> 
                                    </v-container>
                                </v-expansion-panel-text>
                              </v-expansion-panel>
                            </v-col>

                            <!-- FILE REPO -->

                            <v-col md="12" class="pa-1" v-if="element.id == 5">
                              <v-expansion-panel bg-color="#ffffffcf" value="FILES" elevation="5" id="filescard"  @drop="drop($event, {drop: 'evrep'})" @dragover.prevent @dragenter="evRepCardDragOn()" @dragleave="evRepCardDragOff()" @dragend="evRepCardDragOff()">
                                <v-expansion-panel-title class="pa-2">
                                  <span class="text-button text-black"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Evidence File Repository</span>
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                  <span class="expansion-pad expansion-text-dark">Upload or delete evidence files which will be used to associate with this request. You can drag and drop multiple files on this panel to upload.</span>
                                  <v-data-table    
                                        v-if="$store.getters.allEvGetter != null"                                    
                                        show-select
                                        fixed-header
                                        key="alltable"
                                        :headers="allEvidenceListHeaders"
                                        :items="allEvidenceCompute"
                                        item-key='id'
                                        :items-per-page="5"
                                        :search="fileSearch"
                                        :loading="tableLoading"
                                        loading-text="Loading... Please wait"                                             
                                        v-model="filesSelected"                                        
                                        class="elevation-1"
                                        return-object
                                      >
                                        <template v-slot:top>
                                          <div style="display: flex; flex-wrap: wrap;">
                                          <v-text-field v-model="fileSearch" label="Search" density="compact" clearable hide-details class="pa-6"></v-text-field>
                                          <v-btn @click="addMultiEvAssociation()" size="small" color="green" class="ma-2 mt-7 white--text"><v-icon left>mdi-plus</v-icon>Evidence</v-btn>
                                          <v-btn @click="deleteMultiFiles()" size="small" color="red" class="ma-2 mt-7 white--text">Delete<v-icon right>mdi-delete</v-icon></v-btn>
                                          </div>
                                        </template>                                      

                                        <template v-slot:item.comment="{ item }">
                                          <div style="display: flex; flex-wrap: wrap; cursor: pointer;" @click="evidenceComment(item)">
                                            <span class="text-caption" id="listComment">{{ item.comment }}</span><span class="primary--text">...</span>                                            
                                          </div>
                                        </template>

                                        <template v-slot:item.name="{ item }">
                                          <a :href="item.url" target="_blank">{{ item.name }}</a>&nbsp;
                                          <!-- Evidence out of date v-if="item.datediff > 90" -->
                                          
                                                 <v-icon v-if="item.datediff > 90" dark small color="red">mdi-alert-circle</v-icon>
                                             
                                        </template>

                                        <template v-slot:item.actions="{ item }">
                                          <v-icon size="small" @click="deleteFile(item)"> mdi-delete </v-icon>
                                          &nbsp;
                                          <!-- Evidence description -->                                                                                                           
                                          <v-icon size="small"  @click="evidenceComment(item)">mdi-text</v-icon>                                    
                                        </template>

                                        <template v-slot:item.addev="{ item }">
                                          <v-icon size="small" @click="addEvAssociation(item)"> mdi-file-plus </v-icon>
                                        </template>
                                        
                                      </v-data-table>

                                      <v-card-actions>
                                      <v-row align="center" class="pb-3">
                                        <v-col md="12">
                                          <div style="display: flex; flex-wrap: wrap;">
                                          <v-file-input variant="underlined" label="File input" density="compact" v-model="$store.state.chosenFile" hide-details="true" class="text-caption">
                                            <template v-slot:label>
                                              <span class="text-caption">File Input</span>
                                            </template>                                            
                                          </v-file-input>
                                          &nbsp;&nbsp;
                                          <v-btn variant="elevated" size="small" @click="upFile($store.state.chosenFile, {drop: 'evrep-single'})">Upload</v-btn>
                                          </div>                                                                  
                                        </v-col>
                                      </v-row> 
                                    </v-card-actions>                                                                         

                                </v-expansion-panel-text>
                              </v-expansion-panel>
                            </v-col>

                            <!-- ASSOCIATIONS -->

                            <v-col md="12" class="pa-1" v-if="element.id == 6">
                              <v-expansion-panel bg-color="#ffffffcf" value="ASSOC" elevation="5">
                                <v-expansion-panel-title class="pa-2">
                                  <span class="text-button text-black"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Associations</span>
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                  <span class="expansion-text text-black expansion-pad">The following Unique ID's and elements are associated with this test.</span>
                                  <v-data-table
                                        :headers="assocHeaders"
                                        :items="assocTable"
                                        :items-per-page="-1"
                                        :loading="tableLoading"
                                        loading-text="Loading... Please wait"                                             
                                        hide-default-footer
                                        class="elevation-1"
                                      >
                                        <template v-slot:item.key="{ item }">
                                          <div v-if="item.type == 'uid'" style="display: flex; align-items: end; justify-content: flex-start;">
                                            <v-chip>{{ item.key }}</v-chip>
                                          </div>
                                          <div v-else style="display: flex;">
                                            <v-icon color="#8cd0ff" class="handle">mdi-arrow-right-bottom</v-icon>&nbsp;
                                            <v-chip>Element</v-chip>                                            
                                          </div>
                                        </template>

                                        <template v-slot:bottom> </template>


                                      </v-data-table>          
                                </v-expansion-panel-text>
                              </v-expansion-panel>
                            </v-col>

                            <!-- GAPS -->

                            <v-col md="12" class="pa-1" v-if="element.id == 7 && evShowCards.showgaps">
                              <v-expansion-panel bg-color="#c02727cf" value="GAPS" elevation="5">
                                <v-expansion-panel-title class="pa-2">
                                  <span class="text-button text-white"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Gaps</span>
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                  <span class="expansion-text expansion-pad">Gaps that have been identified related to your submitted evidence.</span>
                                  <v-textarea v-model="currentObj.gaps" variant="solo" theme="light"></v-textarea>
                                </v-expansion-panel-text>
                              </v-expansion-panel>
                            </v-col>

                            <!-- TEST RESULTS -->

                            <v-col md="12" class="pa-1" v-if="element.id == 8 && evShowCards.showtresults">
                              <v-expansion-panel bg-color="#0969ab87" value="TEST" elevation="5">
                                <v-expansion-panel-title class="pa-2">
                                  <span class="text-button text-white"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Test Results</span>
                                </v-expansion-panel-title>
                                <v-expansion-panel-text class="expansion-text">
                                  {{ currentObj.type == "AI" ? AItestResults : EROBtestResults }} 
                                </v-expansion-panel-text>
                              </v-expansion-panel>
                            </v-col>

                            <!-- OPTIONS -->

                            <v-col md="12" class="pa-1" v-if="element.id == 9 && evShowCards.showoptions">
                              <v-expansion-panel bg-color="#3d9e3dcf" value="OPTS" elevation="5">
                                <v-expansion-panel-title class="pa-2">
                                  <span class="text-button text-white"><v-icon color="#8cd0ff" class="handle">mdi-drag</v-icon>&nbsp;Options</span>
                                </v-expansion-panel-title>
                                <v-expansion-panel-text class="expansion-text">
                                  Options for completion of this evidence
                                </v-expansion-panel-text>
                              </v-expansion-panel>
                            </v-col>

                        </v-row> 
                        
                      </template>
                        </draggable>
                        </v-expansion-panels>
    
                    </v-container>
                </v-window-item>

            
                <!-- CHAT TAB -->
                <v-window-item style="overflow: auto; height: calc(100vh - 157px);" class="mt-2" value="chat">
                      <v-container class="py-5">
                        <v-row>
                            <v-col md="12">
                                <v-card color="white">
                                    <v-card-title class="text-button">Chat</v-card-title>
                                      <v-card-subtitle>Ask questions about this requirement.</v-card-subtitle>

                                    <v-card-text style="overflow: auto; height: calc(100vh - 350px); display: flex; flex-direction: column-reverse;">
                                      <v-container>
                                        <v-row align="center" :justify="chatAlign(item.userid)" v-for="(item, index) in chatdata" :key="index" class="ma-0">
                                          <v-col md="2" v-if="nameMatches(item.userid)">
                                            <span class="white--text text-h6"><v-avatar color="red" size="48">{{ item.user.charAt(0) }}</v-avatar></span>
                                          </v-col>
                                          <v-col md="10" class="pa-1" :align="chattextAlign(item.userid)">
                                            <div>&nbsp;&nbsp;<span class="text-caption">{{ item.user }}</span></div>
                                            <div class="pa-4 rounded-xl black--text" :style="'display: inline-block; ' + chatColor(item.userid)">{{ item.text }}</div>
                                            <div>&nbsp;&nbsp;<span class="text-caption">{{ item.datetime.toDate().toDateString() + " " + item.datetime.toDate().toLocaleTimeString('en-US') }}</span></div>
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-card-text>
                                  <v-form ref="chatUserForm" @submit.prevent="submitChat()" lazy-validation>
                                    <v-card-actions>                                      
                                      <v-row align="center">
                                        <v-col md="12">
                                          <div style="display: flex;">
                                            <v-text-field v-model="chattext" label="Chat" variant="solo" density="compact" hide-details single-line :rules="textRules" ></v-text-field>                                      
                                            <v-btn class="mx-2" theme="dark" color="primary" type="submit">Send <v-icon theme="dark" right>mdi-send</v-icon></v-btn>
                                          </div>
                                        </v-col>
                                      </v-row>
                                    </v-card-actions>
                                  </v-form>
                                </v-card>
                            </v-col>
                        </v-row>
                      </v-container>
                </v-window-item>

            </v-window>


  <div style="background-color: #2e2e2e; display: flex; flex-wrap: wrap; height: 45px;" class="pt-2">  
        <v-container class="pa-0">
            <v-row class="pa-0 ma-0 pt-0 pl-3" align="center">
                <v-col md="8" class="pa-0">
                  <div style="display: flex;">
                    <v-btn-toggle variant="outlined" theme="dark" density="compact" style="height: 30px; background-color: #4b4e52;" multiple :v-model="[0, 1, 2]">
                        <v-btn size="small" icon="mdi-image-size-select-small" class="pl-8 pr-8" @click="changeWidth('sm')"></v-btn>
                        <v-btn size="small" icon="mdi-image-size-select-large" class="pl-8 pr-8" @click="changeWidth('med')"></v-btn>
                        <v-btn size="small" icon="mdi-image-size-select-actual" class="pl-8 pr-8" @click="changeWidth('lrg')"></v-btn>
                    </v-btn-toggle>
                    &nbsp;&nbsp;
                    <v-btn-toggle variant="outlined" theme="dark" density="compact" style="height: 30px; background-color: #4b4e52;" multiple :v-model="[0, 1, 2]">
                        <v-btn size="small" icon="mdi-menu-left" class="pl-8 pr-8" @click="goBack()"></v-btn>
                        <v-btn size="small" icon="mdi-menu-right" class="pl-8 pr-8" @click="goForward()"></v-btn>
                    </v-btn-toggle>
                  </div>
                </v-col>
                <v-col md="4" class="pa-0" align="right" style="height: 30px;">
                    <v-btn variant="text" class="pl-8 pr-8 no-active" style="height: 30px;" prepend-icon="mdi-content-save" @click="saveEv()" :loading="saving" :disabled="saving">
                        <span>Save</span>
                    </v-btn>
                </v-col>
              </v-row>
        </v-container>
      </div>

        </v-navigation-drawer>

    <confirmation ref="confdlg"></confirmation>

  <v-dialog v-model="imgPreview" max-width="500">
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="ma-0 pa-0 pt-2">
              <v-img max-width="500" :src="this.pasteFileURL"></v-img>
            </v-col>
          </v-row>
          </v-container>
      </v-card-text>
      <v-card-actions>        
        <v-text-field v-model="pasteFileName" color="primary" label="File Name" required hide-details></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="imgPreview = false">Close</v-btn>
        <v-btn color="blue darken-1" text @click="uploadImg(pasteFile); imgPreview = false">Upload</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="evComment" max-width="500">
    <v-card color="#f2f2f2">
      <v-card-title class="text-button">
      Evidence Description
      </v-card-title>      
      <v-card-text class="pb-0">
        <v-textarea v-model="tempComment.comment" solo light></v-textarea>
      </v-card-text>
      <v-card-actions>        
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="evComment = false">Close</v-btn>
        <v-btn color="blue darken-1" text :loading="commentSaving" @click="saveComment(tempComment.id)">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>  

  </div>
</template>

<script>
import Confirmation from '@/components/Confirmation.vue'
import tooltip from '@/components/Tooltip.vue'
import { getFirestore, collection, getDocs, query, where, onSnapshot, addDoc, orderBy, Timestamp, limit, deleteDoc, doc, updateDoc, arrayUnion, arrayRemove, setDoc, writeBatch  } from "firebase/firestore";
import { getStorage, ref, listAll, getMetadata  } from "firebase/storage";
import db from "@/main.js";
import draggable from 'vuedraggable'
//import { AgGridVue } from "ag-grid-vue";

export default {
  name: 'RightNav',
  props: {
      showhide: Boolean,
      },
  data() {
    return {
        tab: null,
        evShowCards: {showpip: false, showgaps: false, showtresults: false, showoptions: false},
        samplestab: null,
        showpip: false,
        showscoring: false,
        showgaps: false,
        showtresults: false,
        showoptions: false,
        showcomment: false,
        rubric: false,
        dragging: "#ffffff",
        dragCnt: 0,
        tabdragging: '',
        currentObj: {poltxt: "Inital", elements: ["Inital"], tierscore: "0", polgaps: ""},
        currentSamples: [],
        navigation: {
            shown: false,
            width: 500,
            borderSize: 3,
        },
        ticksLabels: [
            'Tier 0',
            'Tier 1',
            'Tier 2',
          ],
        chatdata: [],
        chattext: null,
        textRules: [
            v => !!v || 'Text is required',
          ],
        fileListHeaders: [
          { title: 'File Name', align: 'start', key: 'name', width: "30%" },
          { title: 'Description', align: 'start', key: 'comment', width: "20%"},
          { title: 'Uploaded By', key: 'uploadedby.useremail', width: "20%" },
          { title: 'Action', align: 'center', key: 'actions', width: "100px" },

        ],
        assocHeaders: [
          { title: 'ID', align: 'start', key: 'key' },
          { title: 'Requirement', key: 'req' },
        ],
        assocTable: [],       
        chosenFile: null,
        allEvidenceRay: [],
        allEvidenceObj: {},
        fileList: [],
        allFileList: [],
        allEvidenceListHeaders: [
          { title: 'File Name', align: 'start', key: 'name' },
          { title: 'Description', align: 'start', key: 'comment', width: "20%"},          
          { title: 'Uploaded By', key: 'uploadedby.useremail' },
          { title: 'Time', key: 'dtime' },
          { title: 'Action', align: 'center', key: 'actions', width: "100px" },
          { title: 'Evidence', align: 'center', key: 'addev', width: "100px" },           
        ],
        fileSearch: null,
        evfilesSelected: [],        
        unsubscribeChat: null,
        unsubscribeFiles: null,
        unsubscribeAllFiles: null,
        unsubscribePolicyFiles: null,
        unsubscribeProcessFiles: null,
        columnDefs: null,
        rowData: null,
        rowSelection: null,
        gridApi: null,
        columnApi: null,
        filesSelected: [],
        policyFileObj: {},
        policyFileList: [],
        processFileObj: {},
        processFileList: [],
        dragitems: null,
        saving: false,
        batchwrite: null,
        toggle_exclusive: undefined,
        imgPreview: false,
        pasteFile: null,
        pasteFileName: null,
        pasteFileURL: null,
        pasteFileType: null,
        currentSelSample: null,
        evComment: false,
        tempComment: {},
        tableLoading: false,
        sideLoading: false,
        commentSaving: false,
        reComp: false,
        localShowHide: this.showhide,
        sampCards: [
          { id: 1, name: 'Request' },
          { id: 2, name: 'Guidance' },
          { id: 3, name: 'Samples' },
          { id: 4, name: 'Evidence' },
          { id: 5, name: 'Files' },
          { id: 6, name: 'Associations' },
          { id: 7, name: 'Gaps' },
          { id: 8, name: 'Test Results' },
          { id: 9, name: 'Options' },
        ],
        sampPanels: ["REQ", "GUID", "SAMP", "EVID", "FILES", "ASSOC", "GAPS", "TEST", "OPTS"],
        
        
    }
  },

  components: {
    Confirmation,
    draggable,
    tooltip,
    },

  methods: {
    test(){
      //console.log(window.innerWidth)
      this.sideLoading = true
    },
    itemRowBackground(item) {
      if(this.searchEvRay(item) == true){ return 'allsamps'}
      
    },
    saveUserPrefs() {
      this.$store.state.user.prefs.sampPanels = this.sampPanels;
      this.$store.state.user.prefs.sampCards = this.sampCards;
      this.$store.state.user.prefs.evShowCards = this.evShowCards;

      this.$store.dispatch('saveUserPrefs', this.$store.state.user.prefs);
    },

    loadUserPrefs() {
      if (this.$store.state.user && this.$store.state.user.prefs) {

        if (this.$store.state.user.prefs.sampPanels != null) {
          this.sampPanels = this.$store.state.user.prefs.sampPanels;
        }
        if (this.$store.state.user.prefs.sampCards != null) {
          this.sampCards = this.$store.state.user.prefs.sampCards;
        }
        if (this.$store.state.user.prefs.evShowCards != null) {
          this.evShowCards = this.$store.state.user.prefs.evShowCards;
        }
    }
    },        
    evidenceComment(item){
      console.log(item)
      this.tempComment = {}
      this.tempComment.id = item.id
      if ('comment' in item){this.tempComment.comment = item.comment} else {this.tempComment.comment = null}
      this.evComment = true

    },
    async saveComment(id){
      try {
      this.commentSaving = true; //Turn on the loading button
      await setDoc(doc(db, "projects/" + this.$parent.$parent.currentProject + "/evidencefiles/", id), this.tempComment, { merge: true });
      this.commentSaving = false; //Turn off the loading button
      this.evComment = false; //Close the modal
      this.$store.commit('alertpop', { message: 'Saved successfully.', type: "success" });
        } catch(e) {
          this.commentSaving = false; //Turn off the loading button
          this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
          console.error(e);
          } 

    },    
    previewImg(file){
      this.pasteFile = file;
      if(this.currentObj.type == 'AI'){ this.pasteFileName = Date.now() + "-"  + this.currentObj.id + "-" + this.currentSelSample.name + "-screen-shot.png" } 
        else { this.pasteFileName = Date.now() + "-" + this.currentObj.id + "-screen-shot.png" }
      if(this.pasteFileType == 'rep'){this.pasteFileName = Date.now() + "-screen-shot.png"}        
      this.pasteFileURL = URL.createObjectURL(file)
      this.imgPreview = true
    },
    uploadImg(file){
      const newfile = new File([file], this.pasteFileName, 
             {
                 type: file.type,
                 lastModified: file.lastModified
             }
         )
      console.log(newfile)
      if(this.pasteFileType == 'rep'){
        let item = this.currentSelSample
        item.drop = 'evrep'
        this.upFile(newfile, item)
        return
        }
      if(this.currentObj.type == 'AI'){this.upFile(newfile, this.currentSelSample); return}
      if(this.currentObj.type != 'AI'){this.upFile(newfile); return}
      
    },    
    onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;                         
            },
    //Menu sizing --->

    setEvents() {
      //const minSize = this.navigation.borderSize;
      const minSize = 10; // Set your minimum size here
      //const el = this.$refs.drawer.$el.nextSibling;
      const el = document.getElementById("rightDrawer");
      const drawerBorder = this.$refs.border;

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f = window.innerWidth - e.clientX;
        el.style.width = f + "px";
      }


      drawerBorder.addEventListener(
        "mousedown",
        (e) => {
          e.preventDefault();
          if (e.offsetX < minSize) {
            el.style.transition = "initial";
            document.addEventListener("mousemove", resize, false);
          }
        },
        false
      );

      drawerBorder.addEventListener(
        "dblclick",
        (e) => {
          this.navigation.width = 1000
        },
        false
      );

      document.addEventListener(
        "mouseup",
        () => {
          el.style.transition = "";
          this.navigation.width = parseInt(el.style.width, 10);
          this.$store.commit('changedocuRMenuWidth', { width: this.navigation.width });
          document.body.style.cursor = "";
          document.removeEventListener("mousemove", resize, false);
        },
        false
      );
    //End menu sizing ---->

      document.onpaste = (evt) => {
        const dT = evt.clipboardData || window.clipboardData;
        const file = dT.files[ 0 ];
        console.log(file)
        if( file == undefined || file.type != "image/png" ){ return } //ensure file is an image
        
        let cpath = evt.composedPath()
        cpath.forEach((element) => { 
          if (element.id == 'ERCard' || element.id == 'sampleCard'){ 
            this.pasteFileType = "ev"
            this.previewImg(file)
            //console.log("yes found", file)
            }
          if (element.id == 'filescard'){
            this.pasteFileType = "rep"
            this.previewImg(file)
          } 

        })
        //console.log( file, evt.composedPath());
      };

      document.onkeyup  = (e) =>  {
     
      if(e.shiftKey == true && e.ctrlKey == true && e.key == "S"){ this.saveEv() }        
      if(e.shiftKey == true && e.ctrlKey == true && e.key == "U"){ this.goForward() } 
      if(e.shiftKey == true && e.ctrlKey == true && e.key == "Y"){ this.goBack() } 

      };

    },
    changeWidth(size){
      if (size == "sm"){this.navigation.width = (window.innerWidth / 4)}
      if (size == "med"){this.navigation.width = (window.innerWidth / 2)}
      if (size == "lrg"){this.navigation.width = (window.innerWidth / 1.5)}
      this.$store.commit('changedocuRMenuWidth', { width: this.navigation.width });
      return
    },
    updateCurrentObj(value){
      //this.currentObj.policyfiles.push({name: "blah.txt", uploader: "john", page: "77"})
      console.log(value);
    },
    startChat(){
      if (this.unsubscribeChat != null){console.log("Unsubbed"); this.unsubscribeChat();}
      this.chatdata = [];
      console.log("Initiated Start Chat")
      const q = query(collection(db, "projects/" + this.$parent.$parent.currentProject + "/chat"), where("ref", "==", this.$parent.$parent.currentEvID), orderBy("datetime"), limit(20));
      this.unsubscribeChat = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {

          if (change.type === "added") {
            this.chatdata.push(change.doc.data());
            //console.log("Added:", change.doc.data())
          }
          if (change.type === "modified") {
            //Change
          }
          if (change.type === "removed") {
            //Removed
          }
        });

      });

    },
    async submitChat() {
        if(!this.$refs.chatUserForm.validate()){return} //Exit the function if form is not validated
        try {        
        let data = {datetime: Timestamp.now(), ref: this.$parent.$parent.currentEvID, text: this.chattext, user: this.$store.state.user.name, userid: this.$store.state.user.id}
        const docRef = await addDoc(collection(db, "projects/" + this.$parent.$parent.currentProject + "/chat"), data);
        console.log("Document written with ID: ", docRef.id);
        this.chattext = "";
 
          } catch(e) {
            this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
            console.error(e);
            } 
    },
    nameMatches(value){
      if (this.$store.state.user.id == value){ return false } else { return true }      
    },
    chatAlign(value){
      if (this.$store.state.user.id == value){ return "end" } else { return "start" }      
    },
    chattextAlign(value){
      if (this.$store.state.user.id == value){ return "right" } else { return "left" }      
    },
    chatColor(value){
      if (this.$store.state.user.id == value){ return "background-color: #c9e1fe" } else { return "background-color: #e5e5e5" }      
    },
    drop(ev, item) {
      ev.preventDefault();
      console.warn('drop',item)

      if(item != undefined && this.currentObj.type == 'AI' && item.drop != 'evrep'){
        this.tabDragOff(item)
        this.cardDragOff()
      }
      if(item != undefined && item.drop == 'evrep'){
        this.evRepCardDragOff()
      }

      console.log(ev);
      if (ev.dataTransfer.items) {
        console.log("All items: ", ev.dataTransfer.items)
        // Use DataTransferItemList interface to access the file(s)
        ev.dataTransfer.items.forEach((element, i) => {  
          if (element.kind === 'file') {
            this.upFile(element.getAsFile(), item)
            //this.$store.commit('Add2FileQueue', element.getAsFile())
            //console.log('... file[' + i + '].name = ' + element.getAsFile().name);
          }
        })
      } else {
        // Use DataTransfer interface to access the file(s)
         ev.dataTransfer.files.forEach((element, x) => { 
          //console.log('2... file[' + x + '].name = ' + element.name);
        })
      }
    },


    upFile(file, item){
      //this.$store.dispatch('uploadFileAsPromise', file)
      if (file == null){return}
      if(item != undefined && item.drop == "evrep-single"){ this.$store.commit('Add2FileQueue', {file: file[0], project: this.$parent.$parent.currentProject, filetype: 'evrep'}); return }
      if(item != undefined && item.drop == "evrep"){ this.$store.commit('Add2FileQueue', {file: file, project: this.$parent.$parent.currentProject, filetype: 'evrep'}); return }
      if(this.currentObj.type == 'AI'){ this.$store.commit('Add2FileQueue', {file: file, project: this.$parent.$parent.currentProject, filetype: 'sample', sampleid: item.id, evid: this.currentObj.id}); return }
      if(this.currentObj.type != 'AI'){ this.$store.commit('Add2FileQueue', {file: file, project: this.$parent.$parent.currentProject, filetype: 'evidence', evid: this.currentObj.id}); return }
      
    },

    async addEvAssociation(file){

      if( this.currentObj.type == "AI" ){
        await updateDoc(doc(db, "projects/" + this.$parent.$parent.currentProject + "/evidencefiles", file.id), {
            evray: arrayUnion(this.currentObj.id + "&" + this.currentSelSample.id)
        });
        this.$store.commit('alertpop', { message: 'Added file ' + file.name + ' to ' +  this.currentSelSample.name, type: "success" });
      }

      if( this.currentObj.type != "AI" ){
        await updateDoc(doc(db, "projects/" + this.$parent.$parent.currentProject + "/evidencefiles", file.id), {
            evray: arrayUnion(this.currentObj.id)
        });
        this.$store.commit('alertpop', { message: 'Added file ' + file.name + ' to ' +  this.currentObj.id, type: "success" });      }      


    },
    async addMultiEvAssociation(){
      if (this.filesSelected.length == 0){ return }
      const probatch = writeBatch(db);

      this.filesSelected.forEach(item => {
        console.log("foreach item", item)
        if( this.currentObj.type == "AI" ){
          let ref = doc(db, "projects/" + this.$parent.$parent.currentProject + "/evidencefiles/", item.id)
          probatch.update(ref, { evray: arrayUnion(this.currentObj.id + "&" + this.currentSelSample.id) });
        }
        if( this.currentObj.type != "AI" ){
          let ref = doc(db, "projects/" + this.$parent.$parent.currentProject + "/evidencefiles/", item.id)
          probatch.update(ref, { evray: arrayUnion(this.currentObj.id) });
        }

      } ); 


      try {
      //write batch
      await probatch.commit();

      this.$store.commit('alertpop', { message: 'Files associated successfully.', type: "success" });
        } catch(e) {
          this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
          console.error(e);
          }

        this.filesSelected = []          
    },
    
    async remEvAssociation(file){

      if( this.currentObj.type == "AI" ){
        await updateDoc(doc(db, "projects/" + this.$parent.$parent.currentProject + "/evidencefiles", file.id), {
            evray: arrayRemove(this.currentObj.id + "&" + this.currentSelSample.id)
        });
        this.$store.commit('alertpop', { message: 'Removed file ' + file.name + ' from ' +  this.currentSelSample.name, type: "success" });
      }

      if( this.currentObj.type != "AI" ){
        await updateDoc(doc(db, "projects/" + this.$parent.$parent.currentProject + "/evidencefiles", file.id), {
            evray: arrayRemove(this.currentObj.id)
        });
        this.$store.commit('alertpop', { message: 'Removed file ' + file.name + ' from ' +  this.currentObj.id, type: "success" });      }     
    },

    async deleteFile(file) {
      this.$refs.confdlg.open({title: "File Deletion", message: "Are you sure you want to delete this file?" }).then(result => {
            if (result == false){ console.log("Did not delete.") }
            if (result == true){ this.$store.commit('deleteQueue', {file: file, project: this.$parent.$parent.currentProject, type: "evidence"}) }
          });
    },
    async deleteMultiFiles() {
      if (this.filesSelected.length == 0){ return }
      this.$refs.confdlg.open({title: "File Deletion", message: "Are you sure you want to delete these files?" }).then(result => {
            if (result == false){ console.log("Did not delete.") }
            if (result == true){ 
              this.filesSelected.forEach(item => this.$store.commit('deleteQueue', {file: item, project: this.$parent.$parent.currentProject, type: "evidence"}) ); 
              this.filesSelected = []
              }
          });
    },

    pauseFile(){
      this.$store.state.uploadFile.task.pause()
    },
    resumeFile(){
      this.$store.state.uploadFile.task.resume()
    },
    stopFile(){
      this.$store.state.uploadFile.task.cancel()
    },
    polupdate_dlg: function(event) {
      this.$refs.confdlg.open({title: "Policy Update", message: "Another user has updated the policy language in this requirement. Would you like to refresh with the update? You will lose your work. Any further saved changes will overwrite the other users changes." }).then(result => {
        if (result == false){ console.log("Did not update.") }
        if (result == true){ console.log("Updated.") }
        //console.log("Result:", result);
      });
    },
    async saveEv(){
      this.saving = true; //Turn on the loading button

      const batch = writeBatch(db);

      //setup sample object batch write
      if( this.currentObj.type == "AI" ){
        var aicnt = 0
        var ccomplete = 0
        var acomplete = 0
        this.currentSamples.forEach((element) => {
          if(element.id != "allSamples"){
            aicnt++ //add to sample count for completion status
            let ref = doc(db, "projects/" + this.$parent.$parent.currentProject + "/inventory/", element.id)
            let addedData = {}
            addedData.evdata = {}
            addedData.evdata[this.currentObj.id] = {}
            addedData.evdata[this.currentObj.id].score = element.score
            addedData.evdata[this.currentObj.id].assessorcomplete = element.assessorcomplete
            addedData.evdata[this.currentObj.id].complete = element.complete
            addedData.evdata[this.currentObj.id].comment = element.comment
            addedData.evdata[this.currentObj.id].na = element.na
            batch.set(ref, addedData, { merge: true });
            if (element.complete == true){ccomplete++}
            if (element.assessorcomplete == true){acomplete++}
          }
        });
        
      }

      //add EV level changes to batch write
      if( this.currentObj.type == "AI" ){ 
        this.currentObj.score = this.currentAIScore
        if ( aicnt == ccomplete ){ this.currentObj.status = 1 } //if total count of samples are the same as count of completed samples
        if ( aicnt == acomplete ){ this.currentObj.status = 2 }
        } else { 
        //ER or OB
        this.currentObj.score = this.EROBScore
        if( this.currentObj.clientcomplete == true ){ this.currentObj.status = 1 }
        if( this.currentObj.assessorcomplete == true ){ this.currentObj.status = 2 }

        }
      let rref = doc(db, "projects/" + this.$parent.$parent.currentProject + "/evidence/", this.currentObj.id)
      batch.set(rref, this.currentObj, { merge: true });

      try {
      //write batch
      await batch.commit();
      this.saving = false; //Turn off the loading button
      this.$store.commit('alertpop', { message: 'Saved successfully.', type: "success" });
      this.$store.dispatch('auditLog', { project: this.$parent.$parent.currentProject, uid: this.$parent.$parent.currentEvID, action: "Saved Evidence" });
        } catch(e) {
          this.saving = false; //Turn off the loading button
          this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
          console.error(e);
          }
      
      this.updateScores2(this.currentObj.score)


    },
    async updateScores(evScore){
      if (!("uids" in this.currentObj)){ return }
      //setup batch write
      const updatebatch = writeBatch(db);

        this.currentObj.uids.forEach((element) => { //each uid associated with the current object
          console.log(element)
          let uidscoresObj = this.$store.state.docuMaster[this.$parent.$parent.currentProject][element].evidence //this is the object of current evidence scores for the UID in loop
          uidscoresObj[this.currentObj.id].score = evScore //set score for current evidence id in uid object

          //calculate new score for this uid based on current object data
          var addScore = 0
          for (let key in uidscoresObj) {
           addScore += uidscoresObj[key].score //add together scores from all evidence in this UID
          }
          let calcScore = addScore / Object.keys(uidscoresObj).length
          console.log(calcScore, addScore, Object.keys(uidscoresObj).length)
          //Adjust score
          let finalscore = this.scoreAdjust(calcScore)
          //write implementation score to uid's in batch
          let ref = doc(db, "projects/" + this.$parent.$parent.currentProject + "/requirements/", element)
          //If score override is on then just update object
          if (this.$store.state.docuMaster[this.$parent.$parent.currentProject][element].imploverride == true ){updatebatch.update(ref, {"evidence": uidscoresObj})}
            else
              {updatebatch.update(ref, {"evidence": uidscoresObj, "implscore": finalscore})}
          

        })

      //write batch file
      await updatebatch.commit();
      console.log("Ran score updating.")

    },
    async updateScores2(evScore){
      console.log("evscore", evScore)
      if (!("uids" in this.currentObj)){ return }
      //setup batch write
      const updatebatch = writeBatch(db);

        this.currentObj.uids.forEach((element) => { //each uid associated with the current object
          
          let uidscoresObj = this.$store.state.docuMaster[this.$parent.$parent.currentProject][element.uid].elements //this is the object of current evidence scores for the UID in loop
          uidscoresObj[element.element].evidence[this.currentObj.id].score = evScore //set score for current evidence id in uid object
          // //calculate new score for this uid based on current object data
          //var addScore = 0
          for (let elkey in uidscoresObj) { //element loop
            var addevScore = 0
            for (let evkey in uidscoresObj[elkey].evidence) { //evidence loop (through AI/ER in the Element)
              console.log(evkey)
              addevScore += uidscoresObj[elkey].evidence[evkey].score //add together scores from all evidence in this element
            }
            let calcevScore = addevScore / Object.keys(uidscoresObj[elkey].evidence).length
            //let finalelscore = this.scoreAdjust(calcevScore)
            let finalelscore = calcevScore
            //console.log("scoring: ", elkey, calcevScore, finalelscore)
            uidscoresObj[elkey].score = finalelscore //set adjusted score for the current element
          }

          var uidScore = 0
          for (let elkey in uidscoresObj) { //average element scores together for this UID
            uidScore += uidscoresObj[elkey].score //add together scores from all evidence in this UID
          }          
          let calcelScore = uidScore / Object.keys(uidscoresObj).length
          let uidFinalscore = this.scoreAdjust(calcelScore)
          //write implementation score to uid's in batch
          let ref = doc(db, "projects/" + this.$parent.$parent.currentProject + "/requirements/", element.uid)
          //If score override is on then just update object
          if (this.$store.state.docuMaster[this.$parent.$parent.currentProject][element.uid].imploverride == true ){updatebatch.update(ref, {"elements": uidscoresObj})}
            else
              {updatebatch.update(ref, {"elements": uidscoresObj, "implscore": uidFinalscore})}

          

        })

      //write batch file
      await updatebatch.commit();
      console.log("Ran score updating.")

    },    
    async loadSamples(InvNum){
          if (typeof InvNum == "string"){ InvNum = parseInt(InvNum, 10) }
          console.log("inv num: ", InvNum)
          const q = query(collection(db, "projects/" + this.$parent.$parent.currentProject + "/inventory/"), where("inventory", "==", InvNum), where("selected", "==", true));
          const querySnapshot = await getDocs(q);
          let rowD = []
          querySnapshot.forEach((doc) => {
              let modData = doc.data()
              modData.id = doc.id
              modData.comment = ""
              modData.score = 1
              modData.uploaded = false
              modData.complete = false
              modData.assessorcomplete = false
              modData.na = false

              if("evdata" in doc.data() && this.$parent.$parent.currentEvID in doc.data().evdata){
                if("score" in doc.data().evdata[this.$parent.$parent.currentEvID]){ modData.score = doc.data().evdata[this.$parent.$parent.currentEvID].score } 
                if("comment" in doc.data().evdata[this.$parent.$parent.currentEvID]){ modData.comment = doc.data().evdata[this.$parent.$parent.currentEvID].comment } 
                if("complete" in doc.data().evdata[this.$parent.$parent.currentEvID]){ modData.complete = doc.data().evdata[this.$parent.$parent.currentEvID].complete } 
                if("assessorcomplete" in doc.data().evdata[this.$parent.$parent.currentEvID]){ modData.assessorcomplete = doc.data().evdata[this.$parent.$parent.currentEvID].assessorcomplete } 
                if("na" in doc.data().evdata[this.$parent.$parent.currentEvID]){ modData.na = doc.data().evdata[this.$parent.$parent.currentEvID].na } 
              }
                            
              rowD.push(modData)
          });
          
          let allSamples = {}
          allSamples.id = "allSamples"
          allSamples.name = "All Samples"
          allSamples.comment = ""
          rowD.push(allSamples)

          this.currentSamples = rowD
          console.log("current samples:", this.currentSamples[this.samplestab])
          if (this.samplestab == undefined) {this.sampleTabSelected(this.currentSamples[0])} else {this.sampleTabSelected(this.currentSamples[this.samplestab])}
          this.tableLoading = false
          this.sideLoading = false
          
        },

       

    goForward(){
      this.$parent.$parent.moveSel('forward')
    },
    goBack(){
      this.$parent.$parent.moveSel('back')
    },
    sampleTabSelected(item){
      
      this.currentSelSample = item

    },
    tabDragOn(item){
      document.getElementById(item.id).classList.add('tab-on');
    },
    tabDragOff(item){
      document.getElementById(item.id).classList.remove('tab-on');
    },
    cardDragOn(){
      this.dragCnt++
      if(this.dragCnt > 0){document.getElementById("tabItems").classList.add('card-on')}
      //console.log("dragCnt", this.dragCnt)
    },
    cardDragOff(){
      this.dragCnt--
      if(this.dragCnt == 0){document.getElementById("tabItems").classList.remove('card-on')}
      //console.log("dragCnt", this.dragCnt)
    },
    evRepCardDragOn(){
      this.dragCnt++
      if(this.dragCnt > 0){
        document.getElementById("filescard").style.borderColor = null
        document.getElementById("filescard").classList.add('card-on')
        }
      //console.log("dragCnt", this.dragCnt)
    },
    evRepCardDragOff(){
      this.dragCnt--
      if(this.dragCnt == 0){document.getElementById("filescard").classList.remove('card-on')}
      //console.log("dragCnt", this.dragCnt)
    },    

    scoreAdjust(scorein){

      if (isNaN( scorein)){ return 0 }      
      if ( scorein >= 0 &&  scorein <= 10) { return 0 } //Very low
      if ( scorein >= 11 &&  scorein <= 32) { return 25 } //Low
      if ( scorein >= 33 &&  scorein <= 65) { return 50 } //Moderate
      if ( scorein >= 66 &&  scorein <= 89) { return 75 } //High
      if ( scorein >= 90 &&  scorein <= 100) { return 100 } //Very high

      return 0 

    },    
    startLoad(){
      //console.log("START LOAD")
      //console.log("Start", this.$store.state.evMaster[this.$parent.$parent.currentProject][this.$parent.$parent.currentEvID] )
      //Clear currentObj
      this.currentObj = null
      this.tableLoading = true
      this.sideLoading = true
      
      //Move master into currentObj with stringify so that it has no reference
      //this.currentObj = JSON.parse(JSON.stringify(this.$store.state.docuMaster[this.$parent.$parent.currentProject][this.$parent.$parent.currentUID]));
      //let proj = this.$parent.$parent.currentProject
      //let evid = this.$parent.$parent.currentEvID
      //console.log(typeof this.$parent.$parent.currentEvID);

      this.currentObj = JSON.parse(JSON.stringify(this.$store.state.evMaster[this.$parent.$parent.currentProject][this.$parent.$parent.currentEvID]));
      
      if (this.currentObj.type == "AI"){
        
        this.loadSamples(this.currentObj.inventory)        
      }
      if (this.currentObj.type != "AI"){ 

        this.tableLoading = false
        this.sideLoading = false
      }

      this.getAssocList();
      this.startChat();
    },
    searchEvRay(item){
      if (this.currentSelSample.id == 'allSamples'){ return false}
      
      let response = false
      let searchallsamp = this.currentObj.id + "&allSamples"
      item.evray.forEach(item => {
          if(item == searchallsamp){ response = true }
        })      
      
      if (response == true){ return true }
      if (response == false){ return false}  

    },
    getAssocList() {
      
      let assocList = []
      this.currentObj.uids.forEach((forE) => { //each uid associated with the current object
        assocList.push({"key": forE.uid, "req": this.$store.state.docuMaster[this.$parent.$parent.currentProject][forE.uid].req, "type": "uid" })
        assocList.push({"key": forE.element, "req": this.$store.state.docuMaster[this.$parent.$parent.currentProject][forE.uid].elements[forE.element].text, "type": "el" })
      })
      //console.log(assocList)
      this.assocTable = []
      this.assocTable = assocList
    }


  },
  computed: {
    currentEvID (){
      return this.$parent.$parent.currentEvID
    },

    illpolcolor(){
      if ( this.evShowCards.showpip == false ) { return "white" } else { return "green" }
    },
    scorecolor(){
      if ( this.evShowCards.showscoring == false ) { return "white" } else { return "green" }
    },
    gapscolor(){
      if ( this.evShowCards.showgaps == false ) { return "white" } else { return "green" }
    },
    tresultscolor(){
      if ( this.evShowCards.showtresults == false ) { return "white" } else { return "green" }
    },
    optionscolor(){
      if ( this.evShowCards.showoptions == false ) { return "white" } else { return "green" }
    },
  
    AItestResults(){
      if (this.currentObj.type != "AI"){ return }
      let sampcount = 0
      let samptotal = 0
      let passing = 0
      this.currentSamples.forEach((element) => {

        if (element.na == false && element.id != "allSamples"){ 
          let elementscore = 0          
          if ( element.score == 2 ){ elementscore = 100; passing++ } //set element score to 100, or 0 if unscored or failing
          
          samptotal+= elementscore //add sample scores together

          sampcount++ //count total applicable samples
          }
      })

      let sampscore = samptotal / sampcount
      let rndsampscore = 0

      if (isNaN(sampscore)){ rndsampscore = 0 }      

      if (sampscore >= 0 && sampscore <= 10) { rndsampscore = 0 } //Very low
      if (sampscore >= 11 && sampscore <= 32) { rndsampscore = 25 } //Low
      if (sampscore >= 33 && sampscore <= 65) { rndsampscore = 50 } //Moderate
      if (sampscore >= 66 && sampscore <= 89) { rndsampscore = 75 } //High
      if (sampscore >= 90 && sampscore <= 100) { rndsampscore = 100 } //Very high

      let gaps = ""      
      if ( typeof this.currentObj.gaps !== 'undefined' && this.currentObj.gaps.length > 0 ) { gaps = "Gaps Notes: " + this.currentObj.gaps } else { gaps = "No gaps noted."}
      let results = "FD reviewed samples and confirmed that " + passing + " of the of the " + sampcount + " requests were satisfied for a score of " + rndsampscore + "%." + "\n" + gaps
      return results
    },
    currentAIScore(){
      if (this.currentObj.type != "AI"){ return }
      let sampcount = 0
      let samptotal = 0
      this.currentSamples.forEach((element) => {

        if (element.na == false && element.id != "allSamples"){ 
          let elementscore = 0          
          if ( element.score == 2 ){ elementscore = 100 } //set element score to 100, or 0 if unscored or failing
          
          samptotal+= elementscore //add sample scores together

          sampcount++ //count total applicable samples
          }
      })

      let sampscore = samptotal / sampcount

      if (isNaN(sampscore)){ return 0 }      

      if (sampscore >= 0 && sampscore <= 10) { return 0 } //Very low
      if (sampscore >= 11 && sampscore <= 32) { return 25 } //Low
      if (sampscore >= 33 && sampscore <= 65) { return 50 } //Moderate
      if (sampscore >= 66 && sampscore <= 89) { return 75 } //High
      if (sampscore >= 90 && sampscore <= 100) { return 100 } //Very high

      return 0
    },

    EROBtestResults(){
      if (this.currentObj.type == "AI"){ return }

      let score = 0
      let evcount = 0
      if( 'pass' in this.currentObj && this.currentObj.pass == true){ score = 100; evcount = 1}

      let gaps = ""      
      if ( typeof this.currentObj.gaps !== 'undefined' && this.currentObj.gaps.length > 0 ) { gaps = "Gaps Noted: " + this.currentObj.gaps } else { gaps = "No gaps noted."}
      let results = "FD reviewed evidence and confirmed that " + evcount + " of the of the evidence requests were satisfied for a score of " + score + "%." + "\n" + gaps
      return results

    },
    EROBScore(){
      if (this.currentObj.type == "AI"){ return }
      if( 'pass' in this.currentObj && this.currentObj.pass == 2){ return 100 }      
      return 0
    },
    currentTopic(){
      if('topic' in this.currentObj){return this.$store.state.topicList[this.currentObj.topic].name}
      return null      
    },   
    sampleEvidenceCompute(){
      if (this.$store.getters.allEvGetter == null){ return }
      let search = null
      let searchallsamp = this.currentObj.id + "&allSamples"
      let evidence = []

      if (this.currentObj.type == "AI"){ search = this.currentObj.id + "&" + this.currentSelSample.id }
            if (this.currentObj.type != "AI"){ search = this.currentObj.id }
      
      let masterray = this.$store.getters.allEvGetter
      
      masterray.forEach(item => {
        if('evray' in item && (item.evray.includes(search) || item.evray.includes(searchallsamp))){ evidence.push(item)}
      })
      return evidence
      
    }, 
    allEvidenceCompute(){
      if (this.$store.getters.allEvGetter == null){ return }
      return this.$store.getters.allEvGetter
    },   
  },
  beforeMount() {

      },

  mounted() {
    //this.rowData = this.$store.state.docuMasterRay;
    this.loadUserPrefs();
    this.setEvents();
    this.navigation.width = this.$store.state.docuRMenuWidth;

    //console.log("User: ", this.$store.state.user.name);
    console.warn("mounted revnav")
    console.log(this.$parent.$parent.currentUID)
  },
  unmounted() {
    //this.$store.state.docuRMenuWidth = this.navigation.width;
    console.warn("rightevnav unmounted")
    document.onkeyup = null
    document.onpaste = null

    if (this.unsubscribeChat) {
      this.unsubscribeChat();
    }       

    this.saveUserPrefs();
  },

  watch: { //watch for current UID changes
    showhide(newVal) {
      this.localShowHide = newVal;
    },
    "$parent.$parent.currentEvID"(nv) {

      this.startLoad()

    },
    "$store.state.evMasterChange"(nv) {
      console.log("rnav watcher change", nv)
      if (nv.project == this.$parent.$parent.currentProject && nv.id == this.$store.state.currentUID){
        console.log("project match")

        //If policy text of doducment that changed is not the same as the current policy text.
        if ( nv.poltxt != this.currentObj.poltxt ){ 
          console.log("poltxt mismatch")
          this.$refs.confdlg.open({title: "Policy Update", message: "Another user has updated the policy language in this requirement. Would you like to refresh with the update? You will lose your work. Any further saved changes will overwrite the other users changes." }).then(result => {
            if (result == false){ console.log("Did not update.") }
            if (result == true){ this.$set(this.currentObj, 'poltxt', nv.poltxt) }
          });
        }

        //If policy gaps of doducment that changed is not the same as the current policy text.
        if ( nv.polgaps != this.currentObj.polgaps ){ 
          console.log("polgaps mismatch")
          this.$refs.confdlg.open({title: "Gaps Update", message: "Another user has updated the policy gaps language in this requirement. Would you like to refresh with the update? You will lose your work. Any further saved changes will overwrite the other users changes." }).then(result => {
            if (result == false){ console.log("Did not update.") }
            if (result == true){ this.$set(this.currentObj, 'polgaps', nv.polgaps) }
          });
        }

      }
    },
    "$store.state.allEvMasterChange"(nv) {
      console.log("all ev watcher change", nv)
      if (nv.project == this.$parent.$parent.currentProject){
        //on an evidence file change recopy the whole evidence list from the store
        //this.allEvidenceRay = [...this.$store.state.allEvMasterRay[this.$parent.$parent.currentProject]]
        //this.allEvidenceObj = JSON.parse(JSON.stringify(this.$store.state.allEvMaster[this.$parent.$parent.currentProject]));

      }
    },    
  }

};
</script>

<style>
#scrollstyle >>> .v-slide-group__wrapper::-webkit-scrollbar-track
{
	border: 0px solid rgb(0, 0, 0);
	background-color: #F5F5F5;
}

#scrollstyle >>> .v-slide-group__wrapper::-webkit-scrollbar
{
	width: 10px;  
	background-color: #000000;
}

#scrollstyle >>> .v-slide-group__wrapper::-webkit-scrollbar-thumb
{
	background-color: #0066ff86;
  max-height: 20px;
}

.handle {
  cursor: pointer;
}

.v-textarea {
      font-size: 1.0em;
 }

.fixed-tabs-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0rem;
  z-index: 2;
}

.fade-enter-from { opacity: 0 }
.fade-enter-to { opacity: 1 }
.fade-enter-active { transition: all 2s ease }

.fade-leave-from { opacity: 1 }
.fade-leave-to { opacity: 0 }
.fade-leave-active { transition: all 2s ease }

.buttonFocus.v-btn--active::before {
 opacity: 0 !important;
}

.no-active {
  color: rgba(255, 255, 255, 0.7) !important
}

.overscroll >>> .v-slide-group__wrapper {
  overflow-y: auto !important;
}

.v-data-table-header__content {
  font-family: "Roboto", sans-serif !important;
  font-weight: 700;
  font-size: 12px !important;
}

#scrollstyle >>> .v-tab--active {  
  background-color:#e7e7e7 !important;
  color: #000000 !important;
}
.tab-pass {
background-color: #d2fcd4 !important;
color: #000000 !important;
min-height: 50px; 
min-width: 200px;
}
.tab-fail {
background-color: #ffd0d0 !important;
color: #000000 !important;
min-height: 50px; 
min-width: 200px;
}

.tab-normal {
background-color: #ffffff;
color: #000000;
min-height: 50px; 
min-width: 200px;
}

.tab-on {
border-style: solid;
border-width: thin;
border-color: #00ccff;
}

.tab-na {
text-decoration: line-through !important;
}

.card-on {
border-style: solid;
border-width: thin;
border-color: #00ccff !important;
}

#listComment {
    white-space: nowrap;
    text-overflow: clip;
    max-width: 20ch;
    display: block;
    overflow: hidden;
}
.allsamps { 
  background-color: #f7f8ff !important;
  }

 .resize-border {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px; /* Set your border width here */
  cursor: ew-resize;
  background-color: #475361;
  z-index: 9900;
}
.expansion-text {
  color: white;
  padding-bottom: 5px;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  opacity: 0.7;
  overflow: hidden;  
  padding-bottom: 5px;
  text-overflow: ellipsis;
  white-space: pre-wrap !important;
}

.expansion-text-dark {
  color: black;
  padding-bottom: 5px;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  opacity: 0.7;
  overflow: hidden;  
  padding-bottom: 5px;
  text-overflow: ellipsis;
  white-space: pre-wrap !important;
}

.expansion-pad {
  padding-bottom: 15px;
  display: inline-block;
}

.sampleTables {
  display: flex;
  flex-wrap: nowrap;
}

.v-expansion-panel-title--active {
  min-height: 52px !important;
  height: 52px !important;
}
</style>
