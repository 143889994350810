<template>

<v-container v-if="this.$store.getters.loaded == false">
    <v-overlay opacity="1" value="true">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
</v-container>

<v-container v-else class="py-5 pa-0 main-view">
<FileMonitor />

  <div class="whole-table">

    <div id="ev-list-bar" class="pa-1 pr-2">
      <v-icon icon="mdi-border-inside" color="info" class="pt-4 pl-4"></v-icon><span class="text-button pl-4 pt-1" style="color: white; font-size: large;">Evidence Testing</span>
      <v-spacer></v-spacer>

      <tooltip position="left" text="Clear filters">
          <v-btn variant="text" icon="mdi-filter-off" size="small" color="white" @click="clearFilters()"></v-btn>           
      </tooltip>


    <tooltip position="left" text="Assign Users">
        <v-menu v-model="userAssignMenu" :close-on-content-click="false" >
            <template v-slot:activator="{ props }">
                <v-btn variant="text" icon="mdi-account-plus" size="small" color="white" v-bind="props"></v-btn>   
            </template>
          <v-card>
            <v-card-subtitle class="mt-2">
              Assign user to the selected requirements.
            </v-card-subtitle>      
            <v-row justify="center" class="ma-0">
              <v-col md="10">
                <v-select label="User" :items="$store.state.projUserRay" item-title="name" v-model="selectedUser" return-object density="compact" ></v-select>            
              </v-col>
            </v-row>
            <v-row justify="center" class="ma-0">
              <v-col md="10">
                <v-radio-group v-model="userRadios" mandatory="force">
                  <v-radio label="Assign based on current selection" value="selection"></v-radio>
                  <v-radio label="Assign based on current view" value="view"></v-radio>
                </v-radio-group>    
              </v-col>
            </v-row>        
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn variant="text" @click="userAssignMenu = false">Cancel</v-btn>
              <v-btn color="primary" variant="text"  @click="multiUserAssignment()">Assign</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
    </tooltip>

    <tooltip position="left" text="Assign FD Users">
      <v-menu v-model="userFDAssignMenu" :close-on-content-click="false" :nudge-width="300" offset-x>
        <template v-slot:activator="{ props }">
        
                <v-btn variant="text" icon="mdi-account-star" size="small" color="white" v-bind="props"></v-btn>             

        </template>
        <v-card>
          <v-card-subtitle class="mt-2">
            Assign assessor to the selected requirements.
          </v-card-subtitle>      
          <v-row justify="center" class="ma-0">
            <v-col md="10">
              <v-select label="User" :items="$store.state.FDUserRay" item-title="name" v-model="FDselectedUser" return-object  density="compact"></v-select>            
            </v-col>
          </v-row>
          <v-row justify="center" class="ma-0">
            <v-col md="10">
              <v-radio-group v-model="FDuserRadios" mandatory="force">
                <v-radio label="Assign based on current selection" value="selection"></v-radio>
                <v-radio label="Assign based on current view" value="view"></v-radio>
              </v-radio-group>    
            </v-col>
          </v-row>        
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="userFDAssignMenu = false">Cancel</v-btn>
            <v-btn color="primary" text  @click="multiFDUserAssignment()">Assign</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>    
    </tooltip>

    <tooltip position="left" text="Show/hide side bar">                                                  
              <v-btn variant="text" icon="mdi-page-layout-sidebar-right" size="small" color="white" @click="sideBar = !sideBar"></v-btn>      
      </tooltip>
      <tooltip position="left" text="Show/hide right menu">                                        
              <v-btn variant="text" size="small" color="white" class="pt-3" @click="evdrawerright = !evdrawerright">
                <v-icon v-if="evdrawerright == true">mdi-arrow-expand-right</v-icon>
                <v-icon v-if="evdrawerright == false">mdi-arrow-expand-left</v-icon>
              </v-btn>
      </tooltip>     
    </div>
    <ag-grid-vue style="width: 100%; height: calc(100% - 40px);"
        class="ag-theme-material"
        :autoGroupColumnDef="autoGroupColumnDef"
        :groupDisplayType="groupDisplayType"
        :groupRowRendererParams="groupRowRendererParams"              
        :defaultColDef="defaultColDef"
        :columnDefs="columnDefs"
        :rowData="rowData"
        :context="context"        
        :rowGroupPanelShow="rowGroupPanelShow"
        :sideBar="sideBar"
        :groupIncludeFooter="true"
        :aggFuncs="aggFuncs"
        headerHeight="40"
        rowSelection="multiple"
        @grid-ready="onGridReady"
        @column-row-group-changed="onColumnRowGroupChanged"
        @row-selected="onRowSelected"
        @firstDataRendered="onFirstDataRendered">
    </ag-grid-vue>
    </div>



<RightEvNav :showhide="evdrawerright" />
</v-container>
</template>

<script>
import RightEvNav from '@/components/RightEvNav.vue'
import FileMonitor from '@/components/FileMonitor.vue'
import tooltip from '@/components/Tooltip.vue'
/*
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { AgGridVue } from "@ag-grid-community/vue";
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
*/

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue3';

import ListScore from '@/components/ListScore.vue'
import ListEvGroup from '@/components/ListEvGroup.vue'
import ListStatus from '@/components/ListStatus.vue'
import ListAssignedDocuProj from '@/components/ListAssignedDocuProj.vue'
import ListFDAssignedDocuProj from '@/components/ListFDAssignedDocuProj.vue'

import { doc, setDoc, writeBatch } from "firebase/firestore";
import db from "@/main.js";

const topicValueFormatter = params => {
  if(params.node != null && params.node.group == true){ return }
  if(params.value != null){ return params.context.componentParent.$store.state.topicList[params.value].name}
  return null
};

const statusValueFormatter = params => {
    if(params.value == 0){ return "Not Complete" }
    if(params.value == 1){ return "Client Complete" }
    if(params.value == 2){ return "Assessor Complete" }     
    return null  
};    

    export default {
        name: 'EvProject',
        data() {
            return {
                columnDefs: null,
                autoGroupColumnDef: null,
                defaultColDef: null,
                context: null,
                rowData: null,
                rowSelection: null,
                rowGroupPanelShow: null,
                aggFuncs: null,
                gridApi: null,
                columnApi: null,
                sideBar: false,
                groupDisplayType: null,
                groupRowRenderer: null,
                //modules: [ClientSideRowModelModule, AllModules, RowGroupingModule, SetFilterModule, MenuModule, ColumnsToolPanelModule, FiltersToolPanelModule, MultiFilterModule],
                tab: null,
                evdrawerright: false,
                currentEvID: "Initial",
                currentProject: null,
                unsubscribeDocProject: null,
                test1: {},
                breadcrumbs: [
                  {
                    text: 'Vantage',
                    disabled: false,
                    href: '/',
                  },
                  {
                    text: 'Projects',
                    disabled: false,
                    href: '/',
                  },
                  {
                    text: 'Project X',
                    disabled: true,
                    href: '/',
                  },
                ],
                savings: false,
                userAssignMenu: false,
                selectedUser: null,
                userRadios: null,
                userFDAssignMenu: false,
                FDselectedUser: null,
                FDuserRadios: null,                
                ctrlKey: false,
                shiftKey: false,                
                testNav: true,
                testKey: "1",
            }
        },
        components: {
            AgGridVue,
            RightEvNav,
            FileMonitor,
            ListEvGroup,
            tooltip
        },
        methods: {
            test(){
              console.log("store evMasterRay", this.$store.state.evMasterRay[this.currentProject])
              console.log("this rowData", this.rowData)
            },

            onGridReady(params) {
                this.gridApi = params.api;
                this.columnApi = params.columnApi;                     

            },
            onRowSelected(event) {
              if(event.source != "api" && (this.ctrlKey == true || this.shiftKey == true)){ return }       

              if (event.node.selected){
              //Set currently selected UID in the store  
                if ( event.node.data ) { //blank rows arent selected
                  this.$store.state.currentEvID = event.node.data.id;
                  this.currentEvID = event.node.data.id;
                  if ( this.evdrawerright == false ) { this.evdrawerright = true }
                }
              }
            },
            onFirstDataRendered(event) {
              //Check if theres an ID in the URL          
              if(this.$route.query.evid){ 

                let qid = this.$route.query.evid

                //loop through the rows and select the matching ID
                this.gridApi.forEachNode((rowNode) => {
                    if(rowNode.data.id == qid){rowNode.setSelected(true)}
                });

              } else {
                //Wait 1 second and then select the first row   
                setTimeout(this.gridApi.getRowNode('0').setSelected(true),1000)

                window.onkeydown  = (e) =>  {
                  if (e.key == "Control"){this.ctrlKey = true}
                  if (e.key == "Shift"){this.shiftKey = true}
                }
                window.onkeyup  = (e) =>  {
                  if (e.key == "Control"){this.ctrlKey = false}
                  if (e.key == "Shift"){this.shiftKey = false}
                }
                window.onmousemove  = (e) =>  {
                  if (e.ctrlKey == false){this.ctrlKey = false}
                  if (e.ctrlKey == true){this.ctrlKey = true}        
                  if (e.shiftKey == false){this.shiftKey = false}
                  if (e.shiftKey == true){this.shiftKey = true}           
                }   

              }
               
            },
            onColumnRowGroupChanged(event){
              //resize group column when grouped
              this.columnApi.autoSizeColumn('ag-Grid-AutoColumn');
            },
            getSelectedRows() {
                const selectedNodes = this.gridApi.getSelectedNodes();
                const selectedData = selectedNodes.map( node => node.data );
                console.log(selectedData);
                const selectedDataStringPresentation = selectedData.map( node => `${node.make} ${node.model}`).join(', ');
                alert(`Selected nodes: ${selectedDataStringPresentation}`);
            },
            selectRowByID(qid){
                this.gridApi.forEachNode((rowNode) => {
                    if(rowNode.data.id == qid){rowNode.setSelected(true)}
                });
            },
            changeRMenuWidth() {
              this.$store.commit('changedocuRMenuWidth');
            },

            filePop(){
              this.$store.state.filePopup = !this.$store.state.filePopup
            },
            roundScore(score){
              if (score >= 0 && score <= 10) { return 0 } //Very low
              if (score >= 11 && score <= 32) { return 25 } //Low
              if (score >= 33 && score <= 65) { return 50 } //Moderate
              if (score >= 66 && score <= 89) { return 75 } //High
              if (score >= 90 && score <= 100) { return 100 } //Very high
            },
            clearFilters(){
              this.gridApi.setFilterModel(null);
            },
            moveSel(move){
              let currentRow = parseInt(this.gridApi.getSelectedNodes()[0].rowIndex, 10)
              let lastRow = this.gridApi.getDisplayedRowCount() - 1
              let lastRowFooter = this.gridApi.getDisplayedRowAtIndex(lastRow).footer
              let firstRowGroup = this.gridApi.getDisplayedRowAtIndex(0).group              
              let newRow = 0
              //let rowCnt = this.gridApi.getDisplayedRowCount()

              if (move == "forward"){
                  if (currentRow == lastRow){ return }
                  if (currentRow == (lastRow - 1) && lastRowFooter == true){ return }
                  for (let i = (currentRow + 1); i < (currentRow + 20); i++) { 
                    if (this.gridApi.getDisplayedRowAtIndex(i) != undefined && this.gridApi.getDisplayedRowAtIndex(i).group == false){ newRow = i; break }
                    if (i == (currentRow + 19)){ return } //no new rows found that arent groups
                  }
                }

              if (move == "back"){ 
                  if (currentRow == 0){ return }
                  if (currentRow == 1 && firstRowGroup == true){ return }
                  for (let i = (currentRow - 1); i > (currentRow - 20); i--) {
                    if (i < 0) { newRow = currentRow; break }
                    if (this.gridApi.getDisplayedRowAtIndex(i).group == false){ newRow = i; break }
                    if (i == (currentRow - 19)){ return } //no new rows found that arent groups
                  }
                }

              //console.log(rowCnt, newRow, currentRow, this.gridApi.getDisplayedRowAtIndex(currentRow))
              this.gridApi.getDisplayedRowAtIndex(newRow).setSelected(true)
              this.gridApi.getDisplayedRowAtIndex(currentRow).setSelected(false)
            },
            async singleUserAssignment(uid, userdata) {
                try {

                await setDoc(doc(db, "projects/" + this.currentProject + "/evidence/", uid), {assigned: userdata}, { merge: true });

                  } catch(e) {
                    this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
                    console.error(e);
                    }              

            },
            async singleFDUserAssignment(uid, userdata) {
                try {

                await setDoc(doc(db, "projects/" + this.currentProject + "/evidence/", uid), {fdassigned: userdata}, { merge: true });

                  } catch(e) {
                    this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
                    console.error(e);
                    }              

            },          
            async multiUserAssignment() {
              var uidData = []
              if (this.selectedUser == null){ return }
              if (this.userRadios == "selection"){
                let selectedNodes = this.gridApi.getSelectedNodes()
                uidData = selectedNodes.map(node => node.data.id)
              }

              if (this.userRadios == "view"){
                let count = this.gridApi.getDisplayedRowCount();
                let viewData = []
                for (var i = 0; i < count; i++) {
                  var rowNode = this.gridApi.getDisplayedRowAtIndex(i);
                  if (rowNode.group == false){ uidData.push(rowNode.data.id)}                
                }
              }

              if(uidData == null){ return }

              const batch = writeBatch(db);

              uidData.forEach((key) => {
              let ref = doc(db, "projects/" + this.currentProject + "/evidence/", key)
              batch.set(ref, {assigned: this.selectedUser}, { merge: true });
                });              

              try {
              //write batch
              await batch.commit();
              this.$store.commit('alertpop', { message: 'Saved successfully.', type: "success" });
                } catch(e) {
                  this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
                  console.error(e);
                  }
              this.userAssignMenu = false
              console.log(uidData)
            },  
            async multiFDUserAssignment() {
              var uidData = []
              if (this.FDselectedUser == null){ return }
              if (this.FDuserRadios == "selection"){
                let selectedNodes = this.gridApi.getSelectedNodes()
                uidData = selectedNodes.map(node => node.data.id)
              }

              if (this.FDuserRadios == "view"){
                let count = this.gridApi.getDisplayedRowCount();
                let viewData = []
                for (var i = 0; i < count; i++) {
                  var rowNode = this.gridApi.getDisplayedRowAtIndex(i);
                  if (rowNode.group == false){ uidData.push(rowNode.data.id)}                
                }
              }

              if(uidData == null){ return }

              const batch = writeBatch(db);

              uidData.forEach((key) => {
              let ref = doc(db, "projects/" + this.currentProject + "/evidence/", key)
              batch.set(ref, {fdassigned: this.FDselectedUser}, { merge: true });
                });              

              try {
              //write batch
              await batch.commit();
              this.$store.commit('alertpop', { message: 'Saved successfully.', type: "success" });
                } catch(e) {
                  this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
                  console.error(e);
                  }
              this.userFDAssignMenu = false
              console.log(uidData)
            },                 

        },
        beforeCreate(){

        },
        created(){
          
        },
        beforeMount() {
          console.log("route: ", this.$route)
          //A refresh happened and first data is not loaded yet.
          if (this.$store.getters.loaded == false){ this.$store.commit('beginLoading') }

          
          
          //get the current project id from the URL
          this.currentProject = this.$route.query.project
          this.$store.state.currentProject = this.$route.query.project



          //if project is already in store then load data to rows, if not then load database and wait for watcher
          if (this.currentProject in this.$store.state.loadedEvidence && this.$store.state.loadedEvidence[this.currentProject] == true ) {
            //Load data into table if project data was already loaded            
            this.rowData = this.$store.state.evMasterRay[this.currentProject]
            
            //Load title if project data was already loaded
            if (this.$store.state.initializing.projects == true){ document.title = "Vantage - Evidence - " + this.$store.state.projMasterObj[this.currentProject].name }
            } else {
            this.$store.dispatch('loadEvidence', { projid: this.currentProject })
            this.$store.dispatch('loadAllEvidence', { projid: this.currentProject })
            }
          
          //load project UID data if its not loaded already
          if (this.currentProject in this.$store.state.loadedProjects && this.$store.state.loadedProjects[this.currentProject] == true ) {
            console.log('already loaded project UID data')
          } else {
            this.$store.dispatch('loadProject', { projid: this.currentProject })
          }

          this.columnDefs = [
                { headerName: 'ID', field: 'id', sortable: true, filter: 'agTextColumnFilter', resizable: true, width: 100},
                { headerName: 'Type', field: 'typedesc', sortable: true, filter: 'agTextColumnFilter', resizable: true, width: 110, enableRowGroup: true},
                { headerName: 'Topic', field: 'topic', sortable: true, filter: 'agSetColumnFilter', resizable: true, enableRowGroup: true, valueFormatter: topicValueFormatter, filterParams: {valueFormatter: topicValueFormatter}, }, 
                { headerName: 'Request', field: 'description', sortable: true, filter: 'agTextColumnFilter', resizable: true, flex: 1 },
                { headerName: 'Assigned', field: 'assigned.name', sortable: true, filter: true, resizable: true, enableRowGroup: true, cellRenderer: ListAssignedDocuProj, width: 210 },                
                { headerName: 'FD Assigned', field: 'fdassigned.name', sortable: true, filter: true, resizable: true, enableRowGroup: true, cellRenderer: ListFDAssignedDocuProj, width: 210 },                
                { headerName: 'Score', field: 'score', sortable: true, filter: true, resizable: true, cellRenderer: ListScore, aggFunc: 'myAvg', width: 110},
                { headerName: 'Status', field: 'status', sortable: true, filter: 'agSetColumnFilter', cellRenderer: ListStatus, resizable: true, width: 110, enableRowGroup: true, filterParams: {valueFormatter: statusValueFormatter},},
            ];
          this.autoGroupColumnDef = { headerName:'Group',}
          this.defaultColDef = { resizable: true, menuTabs: ['filterMenuTab'], icons: { menu: '<i class="v-icon notranslate mdi mdi-filter-menu" style="font-size: 16px;"/>' },};
          this.aggFuncs = {
              // this overrides the grids built-in sum function
              'myAvg': params => {
                  let sum = 0
                  let cnt = 0
                  params.values.forEach(value => {sum = sum + parseInt(value, 10); cnt++});
                  //console.log(sum, cnt)
                  return  Math.trunc(sum / cnt)
                  //return this.roundScore(sum / cnt);
              }
          }        
          this.rowGroupPanelShow = 'always'
          this.context = {componentParent: this}
          this.groupDisplayType = 'groupRows'
          this.groupRowRendererParams = { innerRenderer: 'ListEvGroup' }

        },

        mounted() { 

          window.addEventListener("message", (event)=>{

                  //console.log("Event data: ", event.data)
                  if( typeof event.data === 'object' && 'evid' in event.data ){ 
                    let currentRow = parseInt(this.gridApi.getSelectedNodes()[0].rowIndex, 10)
                    this.gridApi.getDisplayedRowAtIndex(currentRow).setSelected(false)
                    this.selectRowByID(event.data.evid) 
                    }
                
              });

       

          console.log("mounted home")
          
        },
        unmounted(){

          window.onkeyup = null
          window.onkeydown = null
          window.onmousemove = null

        },
        computed: {
          projName(){
            return this.$store.state.projMasterObj[this.currentProject].name
          }

        },
        watch: { //watch for database changes and update the list
          "$store.state.evMasterChange"(nv) {
            console.log("watcher change", nv)
            if (nv.project == this.currentProject){


              this.gridApi.forEachNode((rowNode, index) => {
                            //if (rowNode.data.id == nv.id){ rowNode.setData(nv) }
                            if (rowNode.group == false && rowNode.data.id == nv.id){ rowNode.setData(nv) }                  
              });

            //this de-selects the row. doesnt work
            //this.gridApi.setGridOption('rowData', this.$store.state.evMasterRay[this.currentProject])

            }
          },
          "$store.state.initializing.projects"(nv) {
            console.log("project change", nv)
            if (nv == true){
              document.title = "Vantage - Evidence - " + this.$store.state.projMasterObj[this.currentProject].name
             }

          },
          "$store.state.loadedEvidence": {
            handler: function (val, oldVal) {
              //if new project is loaded check if its set to true and then load the row data
              if (this.currentProject in val && val[this.currentProject] == true){ 
                console.log("NEW LOAD DATA ROWS")
                this.rowData = this.$store.state.evMasterRay[this.currentProject]

                if (this.$store.state.initializing.projects == true){ document.title = "Vantage - Evidence - " + this.$store.state.projMasterObj[this.currentProject].name }

                //console.log("row data: " + this.$store.state.docuMasterRay[this.currentProject])
                }
      
            },
            deep: true
          }
        }

    }
</script>

<style>

.no-active {
  color: rgba(255, 255, 255, 0.7) !important
}


.ag-theme-alpine div.ag-row {
    font-size: 13px !important;
}

.ag-header-cell-label {
    font-family: "Roboto", sans-serif !important;
    text-transform: uppercase !important;
    font-size: 0.75rem !important;
    font-weight: 500;
    letter-spacing: 0.0892857143em !important;
}

#ev-list-bar {
    display: flex;
    flex-wrap: nowrap;
    background-color: #3d4856;
    white-space: nowrap;
    overflow: hidden;

}

</style>
