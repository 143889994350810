//import Vue from 'vue'
//import Vuex from 'vuex'
//import App from './App.vue'

import { createApp } from 'vue';
import { nextTick } from 'vue';
import App from './App.vue';
import { createStore } from 'vuex';

import router from './router'
//import vuetify from './plugins/vuetify'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})


import VueApexCharts from 'vue3-apexcharts'
import 'ag-grid-enterprise';


import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, query, where, onSnapshot, setDoc, addDoc, doc, getDoc, deleteDoc, orderBy, arrayUnion, arrayRemove } from "firebase/firestore";
import { getAuth, onAuthStateChanged, multiFactor, unlink } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

//import { LicenseManager } from "@ag-grid-enterprise/core";
import { LicenseManager } from "ag-grid-enterprise";

//LicenseManager.setLicenseKey("CompanyName=AB Holdings, LLC,LicensedApplication=Vantage,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-023175,ExpiryDate=8_December_2022_[v2]_MTY3MDQ1NzYwMDAwMA==a3da75e6db0238d797aaef8e2ba42acf");
LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-056084}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{AB_Holdings,_LLC}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Vantage}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Vantage}_need_to_be_licensed___{Vantage}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{2_April_2025}____[v3]_[01]_MTc0MzU0ODQwMDAwMA==ce6b9ec0da7c7b1829cc490f0ed42131");
const firebaseConfig = {
  apiKey: "AIzaSyCQ3i90sd_GXB-DQJrgupBYl0Dp6zuicso",
  authDomain: "fd-preprod.firebaseapp.com",
  projectId: "fd-preprod",
  storageBucket: "fd-preprod.appspot.com",
  messagingSenderId: "168448767575",
  appId: "1:168448767575:web:84ac71c0fb6783b93bd512",
  measurementId: "G-PWLY2Q1142"
};


// Initialize Firebase and Store
const fapp = initializeApp(firebaseConfig);
const db = getFirestore(fapp);
const storage = getStorage();

export default db;

//Firebase authentication
const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    store.state.user.obj = user
    store.state.user.id = user.uid;
    store.state.user.email = user.email;
    store.state.user.name = user.displayName;

    store.state.loggedIn = true;


    console.log("standard user data: ", user);
    console.log("mf user: ", multiFactor(store.state.user.obj))
    store.dispatch('loadUser')

  } else {
    store.state.initializing = {projects: false, roles: false, orgs: false, user: false, topics: false, projusers: false, fdusers: false}    
    store.state.loggedIn = false;
    router.push('login');
    store.commit('RESET_STATE');
    console.log("auth state change: logged out")
  }
});


const getDefaultState = () => {
  return {
    alert: false,
    alertmessage: null,
    alerttype: "warning",
    count: 0,
    currentUID: "Initial", //Currently selected UID
    currentEvID: "Initial", //Currently selected Evidence ID
    currentProject: null,
    chosenFile: null,
    docuMaster: { Initial: {req: "Initial", polscore: "0", procscore: "0"} }, //Master object that has all UID status
    docuMasterRay: {}, //Master array that has all UID status for ag-grid
    docuMasterChange: {}, //The lastest change to the documents for specific UID
    evMaster: { Initial: {id: "Initial", score: "0", type: "AI"} },
    evMasterRay: {},
    evMasterChange: null, 
    allEvMaster: { Initial: {id: "Initial", score: "0", type: "AI"} },
    allEvMasterRay: {update: false},
    allEvMasterChange: null,   
    allDocMaster: { Initial: {id: "Initial", score: "0", type: "AI"} },
    allDocMasterRay: {update: false},      
    docuRMenuWidth: 600, //Right menu width
    orgMaster: {},
    orgMasterRay: [],
    roleMasterRay: [],
    roleMasterObj: {},
    projMasterRay: [],
    projMasterObj: {},
    projUserObj: {},
    projUserRay: [],
    FDUserObj: {},
    FDUserRay: [],    
    InventoryData: {},
    temporaryObj: { poltxt: "Initial"},
    showNavbar: true, //Hide or show the main navbar
    showTopbar: false,
    topicList: {},
    loggedIn: false, //If user is logged in
    userID: null,
    user: {id: null, name: null, email: null, org: null, role: null, title: null, obj: {}, prefs: {}}, //Logged in user data
    FileQueue: [],
    DeleteQueue: [],
    uploadFile: { name: 'No File', status: 'Idle', progress: 0, task: null },
    unsubscribeDocProject: {},
    unsubscribeEvProject: {},
    unsubscribedAllEv: {},
    unsubscribedAllDoc: {},
    projectList: {},
    filePopup: false,
    initializing: {projects: false, roles: false, orgs: false, user: false, topics: false, projusers: false, fdusers: false},
    loadedProjects: {Initial: true},
    loadedEvidence: {Initial: true},
    loadedAllEvidence: {Initial: true},
    loadedAllDocs: {Initial: true},
    getFresh: false,
  }
}

const store = createStore({

  state: getDefaultState(),

  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    unlinkProvider(state, providerId){
      const auth = getAuth();
      unlink(auth.currentUser, providerId).then(() => {
        store.commit('alertpop', { message: 'Your account has been unlinked from ' + providerId + ' authentication.', type: "success" }); 
        console.log(auth.currentUser)
      }).catch((error) => {
        console.log(error)
        store.commit('alertpop', { message: 'An error occured while unlinking your account.', type: "alert" });
      });
    },

    filePopup (state, value) {
      state.filePopup = value
    },
    increment (state) {
      state.count++
    },
    changedocuRMenuWidth (state, payload) {
      state.docuRMenuWidth = payload.width;
      //console.log("Commiting width: ", payload.width);
    },
    showhideNavbar (state, message) {
      state.showNavbar = message
    },
    showhideTopbar (state, message) {
      state.showTopbar = message
    },
    alert (state, message) {
      state.alert = message
    },
    alertpop (state, docdata){
      state.alertmessage = docdata.message;
      state.alerttype = docdata.type;
      //console.log(docdata.message);
      state.alert = true;

    },
    Add2FileQueue(state, payload){
      //If FileQueue is underfined OR zero length.
      if (state.FileQueue.length == 0) {
      //First time
      state.FileQueue.push(payload); //File[0] is File1.Txt
      store.dispatch('uploadFileAsPromise', payload)
      console.log(state.FileQueue);
      //uploadFileAsPromise(state.FileQueue[0]); //Kick off uploads
      } else {
      //Second+ time.
      state.FileQueue.push(payload); //File[1] is File2.txt
      }
    },
    NextFile(state){
      //If FileQueue is NOT underfined AND NOT zero length.
      state.uploadFile.name = "No File"
      state.uploadFile.progress = 0
      state.uploadFile.status = "Idle"
      state.FileQueue.shift();
      if (state.FileQueue.length != 0) {
        store.dispatch('uploadFileAsPromise', state.FileQueue[0]);
        }
      },
    beginLoading(state){
      console.log("Begin Loading...")
      store.dispatch('loadProjects')
      store.dispatch('loadOrgs')
      store.dispatch('loadRoles')
      store.dispatch('loadTopics')
      store.dispatch('loadFDUsers')
    },
    deleteQueue(state, payload){
      if (state.DeleteQueue.length == 0) {
        //First time
        state.DeleteQueue.push(payload); 
        store.dispatch('deleteFiles', payload)
        } else {
        //Second+ time.
        state.DeleteQueue.push(payload); //File[1] is File2.txt
        }
    },
    deleteNext(state){
      state.DeleteQueue.shift();
      if (state.DeleteQueue.length != 0) {
        store.dispatch('deleteFiles', state.DeleteQueue[0]);
        }
      },


  },

  actions: {
    deleteFiles: ({commit}, payload) =>  {
      
      (async  () => {
      try {
        let type = payload.type
        if (type == "policy"){await deleteDoc(doc(db, "projects/" + payload.project + "/policyfiles", payload.file.id));}
        if (type == "evidence"){await deleteDoc(doc(db, "projects/" + payload.project + "/evidencefiles", payload.file.id));}
        store.dispatch('auditLog', { project: payload.project, uid: payload.file.id, action: "File Deleted: " + payload.file.name });
        } catch(e) { console.error(e); }
      console.log(payload)
      store.commit('deleteNext')
    })();
    },
    loadProjects: ({commit}) => {
      (async  () => {
      //Populate list of projects
      console.log("getting projects...")
      try {
      const r = query(collection(db, "projects"));
      const querySnapshot = await getDocs(r);
      store.state.projectList = []; //Clear array
      store.state.projMasterObj = {}
      store.state.projMasterRay.length = 0;
      querySnapshot.forEach((doc) => {
          store.state.projMasterObj[doc.id] = doc.data()
          let datapush = {id: doc.id, name: doc.data().name, orgname: doc.data().orgname};          
          store.state.projMasterRay.push(datapush);    
      });
      } catch(e) {
          console.error(e);
        }
      store.state.initializing.projects = true
      store.state.getFresh = !store.state.getFresh
      console.log("got projects...")
        //console.log(store.state.projectList)
      })();

    },
    loadProjectUsers: ({commit}, payload) => {
      (async  () => {
        let projid = payload.projid
        let orgid = store.state.projMasterObj[projid].org
        //Populate list of project users
        console.log("getting project users...")
        try {
        const r = query(collection(db, "users"), where("organization", "==", orgid), orderBy("name"));
        const querySnapshot = await getDocs(r);
        store.state.projUserRay = []; //Clear array
        querySnapshot.forEach((doc) => {
            store.state.projUserObj[doc.id] = doc.data()
            let datapush = {id: doc.id, name: doc.data().name};
            store.state.projUserRay.push(datapush);    
        });
        } catch(e) {
            console.error(e);
          }
        store.state.initializing.projusers = true
        store.state.getFresh = !store.state.getFresh
        console.log("got project users...")
          
        })();
    },
    loadFDUsers: ({commit}) => {
      (async  () => {
        let orgid = "s1LX58mEEtHoiSTTPTm8" //FD organization
        //Populate list of FD users
        console.log("getting FD users...")
        try {
        const r = query(collection(db, "users"), where("organization", "==", orgid), orderBy("name"));
        const querySnapshot = await getDocs(r);
        store.state.FDUserRay = []; //Clear array
        store.state.FDUserObj = {}
        querySnapshot.forEach((doc) => {
            store.state.FDUserObj[doc.id] = doc.data()
            let datapush = {id: doc.id, name: doc.data().name};
            store.state.FDUserRay.push(datapush);    
        });
        } catch(e) {
            console.error(e);
          }
        store.state.initializing.fdusers = true
        console.log("got FD users...")
          
        })();
    },    
    loadUser: ({commit}) => {
      (async  () => {
      //Populate list of projects
      console.log("getting user data...")
      try {

        const docRef = doc(db, "users", store.state.user.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          store.state.user.org =  docSnap.data().organization
          store.state.user.role =  docSnap.data().role
          store.state.user.title =  docSnap.data().title
          if (docSnap.data().prefs) { store.state.user.prefs =  docSnap.data().prefs }
          if ( 'enrolledmfa' in docSnap.data() ){ store.state.user.enrolledmfa =  docSnap.data().enrolledmfa }
          store.state.initializing.user = true
          store.state.getFresh = !store.state.getFresh
        } else {
          console.log("No such user document!");
        }

      } catch(e) {
          console.error(e);
        }

      console.log("got user...")
        //console.log(store.state.projectList)
      })();

    },
    loadTopics: ({commit}) => {
      (async  () => {
      //Populate list of projects
      console.log("getting user data...")
      store.state.topicList = {}
      try {

        const docRef = doc(db, "topics", "data");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          store.state.topicList = docSnap.data()
          store.state.initializing.topics = true
          store.state.getFresh = !store.state.getFresh
        } else {
          console.log("No such topic document!");
        }

      } catch(e) {
          console.error(e);
        }

      console.log("got topics...", store.state.topicList)

      })();

    },    
    loadOrgs: ({commit}) => {
      //Get list of all organizations
      (async  () => { console.log("getting orgs...")
        try {
        const r = query(collection(db, "organizations"));
        const querySnapshot = await getDocs(r);
        store.state.orgMasterRay = []; //Clear array
        store.state.orgMaster = {}
        querySnapshot.forEach((doc) => {
            //store.state.orgMaster[doc.id] = doc.data();
            store.state.orgMaster[doc.id] = doc.data()
            let buildRay = {id: doc.id, name: doc.data().name, }
            store.state.orgMasterRay.push(buildRay);
        //console.log(store.state.orgMasterRay);
        });
        } catch(e) {
            console.error(e);
          } 
          store.state.initializing.orgs = true
          store.state.getFresh = !store.state.getFresh
          console.log("got orgs...", store.state.orgMaster)
      })();
    },
    loadRoles: ({commit}) => {
      //Populate list of roles
      (async  () => { console.log("getting roles...")
        try {
        const r = query(collection(db, "roles"));
        const querySnapshot = await getDocs(r);

        store.state.roleMasterRay = []; //Clear array
        store.state.roleMasterObj = {}
        querySnapshot.forEach((doc) => {
            //store.state.roleMasterObj[doc.id] = doc.data();
            store.state.roleMasterObj[doc.id] = doc.data()
            let buildRay = {id: doc.id, name: doc.data().name, }
            store.state.roleMasterRay.push(buildRay);
        //console.log(store.state.roleMasterRay);
        });
        } catch(e) {
            console.error(e);
          } 
          store.state.initializing.roles = true
          store.state.getFresh = !store.state.getFresh
          console.log("got roles...", store.state.roleMasterObj)
      })();
    },
    loadProject: ({commit}, payload) => {
      let projid = payload.projid
      console.log("getting data for " + projid)
      //Get data and updated
      if (projid in store.state.unsubscribeDocProject){console.log("Unsubbed Doc Project"); store.state.unsubscribeDocProject[projid]();}
      const q = query(collection(db, "projects/" + projid + "/requirements"));
      store.state.docuMaster[projid] = {}
      store.state.docuMasterRay[projid] = []
      store.state.unsubscribeDocProject[projid] = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          
          if (change.type === "added") {
            let changeData = change.doc.data()
            if(!( 'implscore' in changeData )){ changeData.implscore = 0}
            store.state.docuMaster[projid][change.doc.id] = changeData
            store.state.docuMasterRay[projid].push(changeData);   
          }
          if (change.type === "modified") {
            store.state.docuMaster[projid][change.doc.id] = change.doc.data()
            //change.doc.data().project = this.currentProject
            let docChange = change.doc.data()
            docChange["project"] = projid
            setTimeout(function () {
              store.state.docuMasterChange = {}
              store.state.docuMasterChange = docChange
            }, 500);

            //update docuMasterRay array with changes with the ag-grid
            let index = store.state.docuMasterRay[projid].findIndex(item => item.id === docChange.id);
              if (index !== -1) {
                store.state.docuMasterRay[projid].splice(index, 1, docChange);
              }

            //store.state.docuMasterChange = change.doc.data();
            console.log("dm change: ", store.state.docuMasterChange)
          }
          if (change.type === "removed") {
              //console.log("Removed city: ", change.doc.data());
          }
        });
        //console.log(store.state.docuMaster);
        store.state.loadedProjects[projid] = true;
        if (store.state.initializing.projusers == false){ store.dispatch('loadProjectUsers', { projid: projid }) }
        if (!(projid in store.state.loadedAllDocs)){ store.dispatch('loadAllDocs', { projid: projid }) }
      });
    },
    loadEvidence: ({commit}, payload) => {
      //async
      (async  () => {

      let projid = payload.projid

      //get inventory data
      const docRef = doc(db, "projects/" + projid + "/inventory/", "data");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("Main: Document data:", docSnap.data());
        store.state.InventoryData = docSnap.data()
      } else {
        console.log("Main: No inventory data document!");
      }

      //get evidence
      console.log("getting evidence data for " + projid)
      //Get data and updated
      if (projid in store.state.unsubscribeEvProject){console.log("Unsubbed Ev Project"); store.state.unsubscribeEvProject[projid]();}
      const q = query(collection(db, "projects/" + projid + "/evidence"));
      store.state.evMaster[projid] = {}
      store.state.evMasterRay[projid] = []
      store.state.unsubscribeEvProject[projid] = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          
          if (change.type === "added") {
            store.state.evMaster[projid][change.doc.id] = change.doc.data()
            //do not add to evidence array if it is NA in inventory
            if(change.doc.data().inventory in store.state.InventoryData && store.state.InventoryData[change.doc.data().inventory].na == true){
              console.log(change.doc.data().id, " is NA")
            } else {
              store.state.evMasterRay[projid].push(change.doc.data());   
            }
          }
          if (change.type === "modified") {
            store.state.evMaster[projid][change.doc.id] = change.doc.data()
            //change.doc.data().project = this.currentProject
            let docChange = change.doc.data()
            docChange["project"] = projid

            setTimeout(function () {
              store.state.evMasterChange = {}
              store.state.evMasterChange = docChange
            }, 500);     

            //update evMasterRay array with changes with the ag-grid
            let index = store.state.evMasterRay[projid].findIndex(item => item.id === docChange.id);
              if (index !== -1) {
                store.state.evMasterRay[projid].splice(index, 1, docChange);
              }
                        
            //store.state.docuMasterChange = change.doc.data();
            console.log("ev change: ", store.state.evMasterChange)
          }
          if (change.type === "removed") {
              //console.log("Removed city: ", change.doc.data());
          }
        });
        //console.log(store.state.docuMaster);
        //Vue.set(store.state.loadedEvidence, projid, true)
        store.state.loadedEvidence[projid] = true;
      });

      //end async
      })();
    },
    loadAllEvidence: ({commit}, payload) => {
      //async
      (async  () => {

      let projid = payload.projid

      //get evidence
      console.warn("getting all evidence for " + projid)
      //Get data and updated
      if (projid in store.state.unsubscribedAllEv){console.log("Unsubbed all Ev"); store.state.unsubscribedAllEv[projid]();}
      const q = query(collection(db, "projects/" + projid + "/evidencefiles"), orderBy("timedate", "asc"));
      store.state.allEvMaster[projid] = {}
      store.state.allEvMasterRay[projid] = []
      store.state.unsubscribedAllEv[projid] = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          
          if (change.type === "added") {
            let changeData = change.doc.data()            
            changeData["id"] = change.doc.id
            changeData["dtime"] = change.doc.data().timedate.toDate().toLocaleString()     
            //console.log("file time: ", change.doc.data().timedate)         
            //console.log("now time: ", Date.now())         
            //console.log("difference: ", ((Date.now() / 1000) - change.doc.data().timedate.seconds) / 86400)  
            changeData["datediff"] = ((Date.now() / 1000) - change.doc.data().timedate.seconds) / 86400
            //reactively set array
            let raychange = [...store.state.allEvMasterRay[projid]]
            raychange.unshift(changeData)
            // Vue.set(store.state.allEvMasterRay, projid, raychange)
            store.state.allEvMasterRay[projid] = raychange;
            store.state.getFresh = !store.state.getFresh


          }
          if (change.type === "modified") {
            let changeData = change.doc.data()            
            changeData["id"] = change.doc.id
            changeData["dtime"] = change.doc.data().timedate.toDate().toLocaleString()      
            //reactively set array                        
            let raychange = [...store.state.allEvMasterRay[projid]]
            let indexOfChange = raychange.findIndex(i => i.id === change.doc.id);
            raychange.splice(indexOfChange, 1, changeData)
            //Vue.set(store.state.allEvMasterRay, projid, raychange)    
            store.state.allEvMasterRay[projid] = raychange;
            store.state.getFresh = !store.state.getFresh             

          }
          if (change.type === "removed") {              
              //reactively set array
              let raychange = [...store.state.allEvMasterRay[projid]]
              let indexOfChange = raychange.findIndex(i => i.id === change.doc.id);
              raychange.splice(indexOfChange, 1)
              //Vue.set(store.state.allEvMasterRay, projid, raychange)    
              store.state.allEvMasterRay[projid] = raychange;
              store.state.getFresh = !store.state.getFresh 
          }
        });
        //console.log(store.state.docuMaster);
        //Vue.set(store.state.loadedAllEvidence, projid, true)
        store.state.loadedAllEvidence[projid] = true;
      });

      //end async
      })();
    },   
    loadAllDocs: ({commit}, payload) => {
      //async
      (async  () => {

      let projid = payload.projid

      //get evidence
      console.warn("getting all docs for " + projid)
      //Get data and updated
      if (projid in store.state.unsubscribedAllDoc){console.log("Unsubbed all Docs"); store.state.unsubscribedAllDoc[projid]();}
      const q = query(collection(db, "projects/" + projid + "/policyfiles"), orderBy("timedate", "desc"));
      store.state.allDocMaster[projid] = {}
      store.state.allDocMasterRay[projid] = []
      store.state.unsubscribedAllDoc[projid] = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          
          if (change.type === "added") {
            let changeData = change.doc.data()            
            changeData["id"] = change.doc.id
            changeData["dtime"] = change.doc.data().timedate.toDate().toLocaleString()     
            changeData["datediff"] = ((Date.now() / 1000) - change.doc.data().timedate.seconds) / 86400
            //reactively set array
            let raychange = [...store.state.allDocMasterRay[projid]]
            raychange.unshift(changeData)
            //Vue.set(store.state.allDocMasterRay, projid, raychange)
            store.state.allDocMasterRay[projid] = raychange;
            store.state.getFresh = !store.state.getFresh


          }
          if (change.type === "modified") {
            let changeData = change.doc.data()            
            changeData["id"] = change.doc.id
            changeData["dtime"] = change.doc.data().timedate.toDate().toLocaleString()      
            //reactively set array                        
            let raychange = [...store.state.allDocMasterRay[projid]]
            let indexOfChange = raychange.findIndex(i => i.id === change.doc.id);
            raychange.splice(indexOfChange, 1, changeData)
            //Vue.set(store.state.allDocMasterRay, projid, raychange)    
            store.state.allDocMasterRay[projid] = raychange;
            store.state.getFresh = !store.state.getFresh             

          }
          if (change.type === "removed") {              
              //reactively set array
              let raychange = [...store.state.allDocMasterRay[projid]]
              let indexOfChange = raychange.findIndex(i => i.id === change.doc.id);
              raychange.splice(indexOfChange, 1)
              //Vue.set(store.state.allDocMasterRay, projid, raychange)    
              store.state.allDocMasterRay[projid] = raychange;
              store.state.getFresh = !store.state.getFresh 
          }
        });
        //console.log(store.state.docuMaster);
        store.state.loadedAllDocs[projid] = true
      });

      //end async
      })();
    },      
    uploadFileAsPromise: ({commit}, payload) =>  {
        var evid =  null
        let file = payload.file
        let filetype = payload.filetype
        let filepath = '/policyfiles/'

        if(filetype == "sample"){
          evid = payload.evid
          var sampleid = payload.sampleid
          filepath = '/evidencefiles/'
        }

        if(filetype == "evidence"){
          evid = payload.evid
          filepath = '/evidencefiles/'
        }

        if(filetype == "evrep"){
          filepath = '/evidencefiles/'
        }        

console.warn("upfile: ", evid, payload)
        console.log("file: ", file)
        // Upload file and metadata to the object 'images/mountains.jpg'
        console.log("Upload Project: ", payload.project)
        let fileName = file.name;
        const metadata = {
          customMetadata: {
            uploader: store.state.user.email,
          }
        };
        const storageRef = ref(storage, 'projects/' + payload.project + filepath + Date.now() + "-" + fileName);
        store.state.uploadFile.task = uploadBytesResumable(storageRef, file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        store.state.uploadFile.task.on('state_changed',
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            store.state.uploadFile.progress = progress
            store.state.uploadFile.name = fileName
            //console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case 'paused':
                store.state.uploadFile.status = "Paused"
                console.log('Upload is paused');
                break;
              case 'running':
                store.state.uploadFile.status = "Uploading..."
                //console.log('Upload is running');
                break;
            }
          }, 
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                store.commit('NextFile')
                break;
              case 'storage/canceled':
                store.state.uploadFile.status = "Canceled"
                store.commit('NextFile')
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                store.commit('NextFile')
                break;
            }
          }, 
          () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(store.state.uploadFile.task.snapshot.ref).then(async (downloadURL) => {
              let docuwrite = null
              if (filetype == "policy"){
                let policyassociation = []
                let processassociation = []
                if("policyassociation" in payload){ policyassociation.push(payload.policyassociation) }
                if("processassociation" in payload){ processassociation.push(payload.processassociation) }
                docuwrite = {
                  name: fileName,
                  ref: store.state.uploadFile.task.snapshot.ref.name,
                  url: downloadURL,
                  timedate: new Date(),
                  uploadedby: {
                    name: store.state.user.name,
                    fullemail: store.state.user.email,
                    useremail: store.state.user.email.match(/^(.+)@/)[1],
                    userid: store.state.user.id,
                  },
                  policyassociation: policyassociation,
                  processassociation: processassociation,
                }
              }
              if (filetype == "sample"){
                docuwrite = {
                  name: fileName,
                  ref: store.state.uploadFile.task.snapshot.ref.name,
                  url: downloadURL,
                  timedate: new Date(),
                  evid: evid,
                  sampleid: sampleid,
                  evray: arrayUnion(evid + "&" + sampleid),
                  uploadedby: {
                    name: store.state.user.name,
                    fullemail: store.state.user.email,
                    useremail: store.state.user.email.match(/^(.+)@/)[1],
                    userid: store.state.user.id,
                  },
                }
              }
              if (filetype == "evidence"){
                docuwrite = {
                  name: fileName,
                  ref: store.state.uploadFile.task.snapshot.ref.name,
                  url: downloadURL,
                  timedate: new Date(),
                  evid: evid,
                  evray: arrayUnion(evid),
                  uploadedby: {
                    name: store.state.user.name,
                    fullemail: store.state.user.email,
                    useremail: store.state.user.email.match(/^(.+)@/)[1],
                    userid: store.state.user.id,
                  },
                }
              }
              if (filetype == "evrep"){
                docuwrite = {
                  name: fileName,
                  ref: store.state.uploadFile.task.snapshot.ref.name,
                  url: downloadURL,
                  timedate: new Date(),
                  evid: evid,
                  uploadedby: {
                    name: store.state.user.name,
                    fullemail: store.state.user.email,
                    useremail: store.state.user.email.match(/^(.+)@/)[1],
                    userid: store.state.user.id,
                  },
                }
              }                    
              const docRef = await addDoc(collection(db, "projects/" + payload.project + filepath), docuwrite);
              console.log("Document written with ID: ", docRef.id);
              console.log('File available at', downloadURL);
              store.dispatch('auditLog', { project: payload.project, uid: evid, action: "File Uploaded: " + fileName });
            });
            if(store.state.chosenFile != null && 'name' in store.state.chosenFile && fileName == store.state.chosenFile.name){store.state.chosenFile = null} //reset the single file upload boxes
            store.commit('NextFile')
          }
        );


    },
    auditLog: ({commit}, payload) =>  {
      if (payload == null || payload == undefined){ return }
      (async  () => {
      let docuwrite = null
      let project = payload.project
      let uid = payload.uid
      let action = payload.action
      let orgnam = store.state.orgMaster[store.state.user.org].name

      docuwrite = {
        timedate: new Date(),
        orgid: store.state.user.org,
        orgname: orgnam,
        userid: store.state.user.id,
        username: store.state.user.name,
        userobj: JSON.stringify(store.state.user.obj),
        email: store.state.user.email,
        uid: uid,
        action: action,

      }
      const docRef = await addDoc(collection(db, "projects/" + project + "/logs"), docuwrite);

    })()
    },
    saveUserPrefs: ({commit}, payload) =>  {
      if (payload == null || payload == undefined){ return }
      (async  () => {

      try {
        await setDoc(doc(db, "users", store.state.user.id),  { prefs: payload }, { merge: true });
        console.log("writing user prefs", payload)
      }

      catch(e) {
        console.error("Couldn't write user prefs", e);
      }

    })()
    }    

  },
  getters: {
    loaded: state => {
      store.state.getFresh
      if (store.state.initializing.orgs == true && store.state.initializing.projects == true && store.state.initializing.roles == true && store.state.initializing.user == true  && store.state.initializing.topics == true && store.state.initializing.fdusers == true){ 
        return true 
      }      
      return false
    },
    allEvGetter: state => {
      store.state.getFresh
      let projid = store.state.currentProject
      if ( store.state.loadedAllEvidence[projid] == true ) { return store.state.allEvMasterRay[projid] }
      return null
    },
    allDocGetter: state => {
      store.state.getFresh
      let projid = store.state.currentProject
      if ( store.state.loadedAllDocs[projid] == true ) { return store.state.allDocMasterRay[projid] }
      return null
    },    
    allowMfa: state => {
      if (store.state.user.org != null && store.state.user.org in store.state.orgMaster){ 
        if ( store.state.orgMaster[store.state.user.org].mfa == true ){ return true }
       }
      return false
    },    
    enforceMfa: state => {
      if (store.state.user.org != null && store.state.user.org in store.state.orgMaster){ 
        if ( store.state.orgMaster[store.state.user.org].enforcemfa == true ){ return true }
       }
      return false      
    },    
  }

})

/*
new Vue({
  el: "#app",
  router,
  vuetify,
  store: store,
  created: function(){
    //store.commit('loadProjects')
  if( store.loggedIn == true ){ store.commit('beginLoading') }
    

    console.log("created store")
  },
  mounted: function(){
    console.log("mounted store")
  },
  render: h => h(App)
}).$mount('#app')
*/


const app = createApp(App);

// Use Vuex store
app.use(store);

// Use router and vuetify if needed
app.use(router);
app.use(vuetify);

//app.use(VueApexCharts)
app.component('apexchart', VueApexCharts)

//begin the loading process
if (store.state.loggedIn === true) {
  store.commit('beginLoading');
}
console.log('created store');



app.mount('#app');