<template>

<v-container v-if="this.$store.getters.loaded == false">
    <v-overlay opacity="1" value="true">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
</v-container>

<v-container v-else class="py-5 main-view">

    <div class="whole-table">

        <div id="ev-list-bar" class="pa-1 pr-2">

              <v-icon icon="mdi-border-inside" color="info" class="pt-4 pl-4"></v-icon><span class="text-button pl-4 pt-1" style="color: white; font-size: large;">Project Inventory</span>
              <v-spacer></v-spacer>
              <div style="display: flex; flex-wrap: wrap; align-items: center; justify-content: center;" class="pt-1">
                <v-chip size="small" color="white" label @click="test2()">Samples: {{ rowCount }}</v-chip>&nbsp;<v-chip size="small" color="white" label>Selected: {{ selectedRows }}</v-chip>
                <v-autocomplete density="compact" class="text-white pl-3 pb-2" variant="underlined" hide-details return-object v-model="SelectedInventory" :items="Inventory" item-title="name"  @update:modelValue="confirmNav()" style="font-size: 10px; width: 300px;"></v-autocomplete>                
              </div>

        </div>


        <ag-grid-vue style="width: 100%; height: calc(100% - 95px);"
            class="ag-theme-material"
            :defaultColDef="defaultColDef"
            :context="context"
            :columnDefs="columnDefs"
            :enableRangeSelection="true"
            :rowData="rowData"
            :rowSelection="rowSelection"
            :loadingOverlayComponent="loadingOverlayComponent"
            :loadingOverlayComponentParams="loadingOverlayComponentParams"
            headerHeight="40"
            @grid-ready="onGridReady"
            @row-selected="onRowSelected"
            @cell-value-changed="onCellValueChanged"
            @row-data-updated="onRowDataUpdated"
            :processDataFromClipboard="processDataFromClipboard"
            >
        </ag-grid-vue>

        <v-toolbar color="#475361" dark flat height=50>
          <v-row>
             <v-col md="6" align-self="center">
               <v-btn size="small" width="110" variant="elevated" color="green" class="ml-2 text-white" @click="addEmptyRow()"><v-icon left>mdi-plus</v-icon>Add Row</v-btn>&nbsp;
               <v-btn size="small" width="110" variant="elevated" color="red" class="text-white" @click="remSelectedRows()"><v-icon left>mdi-minus</v-icon>Rem Row</v-btn>&nbsp;
               <v-btn size="small" width="110" variant="elevated" color="primary" class="text-white" @click="selectRandom()"><v-icon left>mdi-checkbox-outline</v-icon>Random</v-btn>&nbsp;
               <v-btn size="small" width="110" variant="elevated" color="primary" class="text-white" @click="invinfo = true"><v-icon left>mdi-information-outline</v-icon>Info</v-btn>
             </v-col>

             <v-col md="6" align-self="center">
              <div style="display: flex; flex-wrap: wrap; justify-content: right; align-items: center;;" v-if="SelectedInventory != null">
                <v-checkbox hide-details v-model="InventoryData[SelectedInventory.id].complete"><template v-slot:label><span class="text-caption">Complete</span></template></v-checkbox>
                <v-checkbox hide-details v-model="InventoryData[SelectedInventory.id].assessorcomplete" v-if="InventoryData[SelectedInventory.id]?.complete == true" class="pl-2"><template v-slot:label><span class="text-caption">Assessor Complete</span></template></v-checkbox>
                <v-checkbox hide-details v-model="InventoryData[SelectedInventory.id].na" color="red" class="pl-2 pr-2" @change="setNAOverlay()"><template v-slot:label><span class="text-caption">N/A</span></template></v-checkbox>
                <v-btn size="small" width="110"  variant="elevated" color="primary" class="ma-2 text-white" @click="saveInv()"><v-icon left>mdi-content-save</v-icon>Save</v-btn>
              </div>
             </v-col>
          </v-row>
        </v-toolbar>

      </div>

    <v-dialog v-model="invinfo" max-width="500" v-if="SelectedInventory != null">
        <v-toolbar color="primary" dark flat height=60>
            <v-toolbar-title class="text-subtitle-1">Inventory Info</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon class="pr-5">mdi-information</v-icon>   
        </v-toolbar>
      <v-form ref="invinfoForm" lazy-validation>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0 pt-2">
                <v-text-field v-model="InventoryData[SelectedInventory.id].origin" prepend-icon="mdi-label" solo label="Population Origin*"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-menu
                v-model="fmenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ props }">
                  <v-text-field
                    v-model="InventoryData[SelectedInventory.id].fdate"
                    label="From Date*"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="props"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="InventoryData[SelectedInventory.id].fdate"
                  @input="fmenu = false"
                ></v-date-picker>
              </v-menu>

              <v-menu
                v-model="tmenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ props }">
                  <v-text-field
                    v-model="InventoryData[SelectedInventory.id].tdate"
                    label="To Date*"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="props"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="InventoryData[SelectedInventory.id].tdate"
                  @input="tmenu = false"
                ></v-date-picker>
              </v-menu>

            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click="invinfo = false">Close</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>

<confirmation ref="confdlg"></confirmation>
</v-container>
</template>

<script>
import Confirmation from '@/components/Confirmation.vue'
import ListCheckbox from '@/components/ListCheckbox.vue'
import CustomLoadingOverlay from '@/components/CustomOverlay.vue';
/*
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { AgGridVue } from "@ag-grid-community/vue";
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
*/

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue3';


import { getFirestore, collection, getDoc, getDocs, query, where, onSnapshot, addDoc, orderBy, Timestamp, limit, deleteDoc, doc, updateDoc, arrayUnion, arrayRemove, setDoc, writeBatch  } from "firebase/firestore";
import db from "@/main.js";

    export default {
        name: 'Home',
        data() {
            return {
                columnDefs: null,
                defaultColDef: { editable: true, filter: true, resizable: true, minWidth: 100 },
                rowData: null,
                frowData: null,
                rowSelection: "multiple",
                gridApi: null,
                columnApi: null,
                currentProject: null,
                context: null,
                //modules: [ClientSideRowModelModule, RangeSelectionModule, MenuModule, ClipboardModule],
                loadingOverlayComponent: 'CustomLoadingOverlay',
                loadingOverlayComponentParams: null,
                valueChange: false,
                SelectedInventory: {id: 1, name: "01: Servers"},
                SelectedInventory2: null,
                currentInventory: null,
                invinfo: false,
                popDates: [],
                reComp: false,
                fmenu: null,
                tmenu: null,
                selectedRows: 0,
                Inventory2: [
                  "01: Servers",
                  "02: Network Devices",
                  "03: SIEM",
                  "04: Wireless Access Points",
                  "05: Remote Access VPN",
                  "06: Workstations",
                  "07: Facilities",
                  "08: Applications",
                  "09: Data Repositories",
                  "10: Third Party Service Providers",
                  "11: IT Personnel Privileged",
                  "12: Personnel Newly Hired",
                  "13: Personnel Employed",
                  "14: Personnel Terminated",
                  "15: Non-Employees With Access",
                  "16: Remote Personnel",
                  "17: Non-Employees Terminated",
                  "18: IDS/IPS",
                  "19: Dial-Up Servers",
                  "20: Incident Response",
                  "21: Change of Roles",
                  "22: Mobile Devices",
                  "23: Removable Media",
                  "24: Bring Your Own Device",
                  "25: Collabrative Devices",
                  "27: Ethics Hotline Complaint",
                  "28: Commercial Products",
                  "29: System Changes",
                  "30: Asset Movement Status",
                  "31: Identified Vulnerabilities",
                  "32: System Connections",
                  "33: Network Service Provider",
                  "34: Sensitive Data Transmitted",
                  "35: Password Reset Tickets",
                  "36: System Maintenance Records",
                  "37: Information Security Incidents",
                  ],
                Inventory: [
                { id: 1, name: "01: Servers" },
                { id: 2, name: "02: Network Devices" },
                { id: 3, name: "03: SIEM" },
                { id: 4, name: "04: Wireless Access Points" },
                { id: 5, name: "05: VPN" },
                { id: 6, name: "06: Desktops" },
                { id: 7, name: "07: Facilities" },
                { id: 8, name: "08: Applications" },
                { id: 9, name: "09: Databases" },
                { id: 10, name: "10: Third Party Service Providers" },
                { id: 11, name: "11: Privileged Personnel" },
                { id: 12, name: "12: Personnel Newly Hired" },
                { id: 13, name: "13: Current Personnel" },
                { id: 14, name: "14: Personnel Terminated" },
                { id: 15, name: "15: Contractors" },
                { id: 16, name: "16: Remote Personnel" },
                { id: 17, name: "17: Non-Employees Terminated" },
                { id: 18, name: "18: IDS/IPS" },
                { id: 19, name: "19: Dial-Up Servers" },
                { id: 20, name: "20: Incident Response" },
                { id: 21, name: "21: Change of Roles" },
                { id: 22, name: "22: Mobile Devices" },
                { id: 23, name: "23: Removable Media" },
                { id: 24, name: "24: BYOD" },
                { id: 25, name: "25: Collaborative Devices" },
                { id: 26, name: "26: Ethics Hotline Complaint" },
                { id: 27, name: "27: Commercial Products" },
                { id: 28, name: "28: System Changes" },
                { id: 29, name: "29: Asset Movement Status" },
                { id: 30, name: "30: Identified Vulnerabilities" },
                { id: 31, name: "31: System Connections" },
                { id: 32, name: "32: Network Service Provider" },
                { id: 33, name: "33: Sensitive Data " },
                { id: 34, name: "34: Password Reset Tickets" },
                { id: 35, name: "35: System Maintenance Records" },
                { id: 36, name: "36: Information Security Incidents" },
                { id: 37, name: "37: Public Posting Staff" },
                { id: 38, name: "38: Transported Removable Media" },
                { id: 39, name: "39: Key Management Personnel" },
                { id: 40, name: "40: Restricted Wireless Access" },
                { id: 41, name: "41: Application Changes" },
                { id: 42, name: "42: Database Changes" },
                { id: 43, name: "43: Configuration Changes" },
                { id: 44, name: "44: Network Device Changes" },
                { id: 45, name: "45: Operating System Changes" },
                { id: 46, name: "46: Vendor Supplied Software" },
                { id: 47, name: "47: Firewalls" },
                { id: 48, name: "48: Compromised Passwords" },
                { id: 49, name: "49: System Development" },
                { id: 50, name: "50: Vendors with Logical Access" },
                { id: 51, name: "51: Data Exchange Agreements" },
                { id: 52, name: "52: Record Storage" },
                { id: 53, name: "53: IS Legal Incidents" },
                { id: 54, name: "54: Backup Records" },
                { id: 55, name: "55: Restoration Records" },
                { id: 56, name: "56: Sensitive Data Transmissions" },
                { id: 57, name: "57: Newly Purchased Assets" },
                { id: 58, name: "58: Interconnected Systems" },
                { id: 59, name: "59: E-Commerce Agreements" },
                { id: 60, name: "60: Seeded Accounts" },
                { id: 61, name: "61: System Accounts" },
                { id: 62, name: "62: Shared Accounts" },
                { id: 63, name: "63: Job Roles" },
                { id: 64, name: "64: Privileged Account Requests" },
                { id: 68, name: "68: Outsourced Software Development" },
                { id: 70, name: "70: Sensitive Applications" },
                { id: 71, name: "71: Sensitive Data" },
                { id: 72, name: "72: Third-party Users" },
                { id: 73, name: "73: Ethics Hotline Complaints" },

                ],
                InventoryData: {},
                OneData: null,
                TwoData: null,
                Def1: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Hostname*', field: 'name', headerTooltip: 'SERVER1'},
                { headerName: 'Description / Function', field: 'desc', headerTooltip: 'Production Apache web server located in the DMZ.', flex: 1},
                { headerName: 'Physical Location', field: 'loc', headerTooltip: 'Atlanta, GA'},
                { headerName: 'Virtual (Y/N)', field: 'virtual', headerTooltip: 'Yes/No', cellRenderer: ListCheckbox, editable: false},
                ],
                Def2: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Hostname*', field: 'name', headerTooltip: 'FIREWALL1'},
                { headerName: 'Description / Function', field: 'desc', headerTooltip: 'Production firewall which is part of an active/passive pair.' , flex: 1},
                { headerName: 'Physical Location', field: 'loc', headerTooltip: 'Atlanta, GA'},
                { headerName: 'Make / Model', field: 'make', headerTooltip: 'Cisco 3925'},
                { headerName: 'Virtual (Y/N)', field: 'virtual', headerTooltip: 'Yes/No', cellRenderer: ListCheckbox, editable: false},
                ],
                Def3: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'SIEM Name*', field: 'name', },
                { headerName: 'Description / Function', field: 'desc', flex: 1},
                { headerName: 'Physical Location', field: 'loc', },
                ],                
                Def4: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Asset Name*', field: 'name', },
                { headerName: 'Description / Function', field: 'desc', flex: 1},
                { headerName: 'Physical Location', field: 'loc', },
                ],                
                Def5: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'VPN Product Name*', field: 'name', },
                { headerName: 'Description / Function', field: 'desc', flex: 1},
                ],                
                Def6: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Workstation Name*', field: 'name', },
                { headerName: 'Description / Function', field: 'desc', flex: 1},
                { headerName: 'Physical Location', field: 'loc', },
                ],   
                Def7: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Locations Name*', field: 'name', },
                { headerName: 'Address', field: 'address', },
                { headerName: 'Function (Primary / Secondary / DR / Data Center)', field: 'desc', flex: 1},                
                ],                   
                Def8: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Application Name*', field: 'name', },
                { headerName: 'Application Purpose', field: 'purpose',},
                { headerName: 'Application Description', field: 'desc', flex: 1},
                { headerName: 'Application Location', field: 'loc', },
                { headerName: 'Application Vendor', field: 'vendor', },
                ],   
                Def9: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Name*', field: 'name', },
                { headerName: 'Description / Function', field: 'desc', flex: 1},
                ], 
                Def10: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Name of Provider*', field: 'name', },
                { headerName: 'Service(s) Provided', field: 'desc', flex: 1},
                ],
                Def11: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Last Name*', field: 'name', },
                { headerName: 'First Name*', field: 'firstname', },
                { headerName: 'Role', field: 'role', flex: 1 },
                { headerName: 'User ID (non-privileged)', field: 'userid',},
                { headerName: 'User ID (privileged)', field: 'useridp',},
                ],      
                Def12: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Last Name*', field: 'name', },
                { headerName: 'First Name*', field: 'firstname', },
                { headerName: 'Role', field: 'role', flex: 1 },
                { headerName: 'User ID', field: 'userid',},
                { headerName: 'Hire Date', field: 'hired',},
                ],    
                Def13: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Last Name*', field: 'name', },
                { headerName: 'First Name*', field: 'firstname', },
                { headerName: 'Role', field: 'role', flex: 1 },
                { headerName: 'User ID', field: 'userid',},
                { headerName: 'Hire Date', field: 'hired',},
                ],
                Def14: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Last Name*', field: 'name', },
                { headerName: 'First Name*', field: 'firstname', },
                { headerName: 'Role', field: 'role', flex: 1 },
                { headerName: 'User ID', field: 'userid',},
                { headerName: 'Term Date', field: 'term',},
                ],
                Def15: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Last Name*', field: 'name', },
                { headerName: 'First Name*', field: 'firstname', },
                { headerName: 'Role', field: 'role', },
                { headerName: 'User Organization', field: 'userorg', flex: 1 },
                { headerName: 'User ID', field: 'userid',},
                { headerName: 'Access Approval Date', field: 'approved',},
                ],
                Def16: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Last Name*', field: 'name', },
                { headerName: 'First Name*', field: 'firstname', },
                { headerName: 'Title', field: 'title', },
                { headerName: 'User Location', field: 'location', flex: 1 },
                { headerName: 'Access Approval Date', field: 'approved',},
                ],
                Def17: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Last Name*', field: 'name', },
                { headerName: 'First Name*', field: 'firstname', },
                { headerName: 'Role', field: 'role', flex: 1 },
                { headerName: 'User ID', field: 'userid',},
                { headerName: 'Term Date', field: 'term',},
                ],                                                                                                                 
                Def18: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Workstation Name*', field: 'name', },
                { headerName: 'Description / Function', field: 'desc', flex: 1},
                { headerName: 'Physical Location', field: 'loc', },
                ],  
                Def19: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Workstation Name*', field: 'name', },
                { headerName: 'Description / Function', field: 'desc', flex: 1},
                { headerName: 'Network Connection Method', field: 'method', },
                { headerName: 'Description of Dial-Up Connection Uses', field: 'uses', },
                { headerName: 'Physical Location', field: 'loc', },
                ], 
                Def20: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Last Name*', field: 'name', },
                { headerName: 'First Name*', field: 'firstname', },
                { headerName: 'Role', field: 'role', },
                { headerName: 'Responsibilities', field: 'responsibilities', flex: 1},
                ],
                Def21: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Last Name*', field: 'name', },
                { headerName: 'First Name*', field: 'firstname', },
                { headerName: 'Previous Role', field: 'prevrole', },
                { headerName: 'Current Role', field: 'role', },
                { headerName: 'User ID', field: 'userid', flex: 1 },
                { headerName: 'Reassigned Date', field: 'date', },
                ],                 
                Def22: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Device Hostname*', field: 'name', },
                { headerName: 'Device Type', field: 'type', },
                { headerName: 'Asset ID', field: 'assetid', },
                { headerName: 'Operating System', field: 'os', flex: 1 },
                { headerName: 'Assignee', field: 'assignee', },                
                ],   
                Def23: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Label*', field: 'name', },
                { headerName: 'Device Type', field: 'type', },
                { headerName: 'Description', field: 'desc', flex: 1 },
                { headerName: 'Owner', field: 'owner', },                
                ], 
                Def24: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Label*', field: 'name', },
                { headerName: 'Device Type', field: 'type', },
                { headerName: 'Description', field: 'desc', flex: 1 },
                { headerName: 'Owner', field: 'owner', },                
                ],                 
                Def25: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Asset ID*', field: 'name', },
                { headerName: 'Device Type', field: 'type', },                
                { headerName: 'Make / Model', field: 'model', },
                { headerName: 'Device Location', field: 'loc', },
                { headerName: 'Device Authentication', field: 'auth', flex: 1 },
                { headerName: 'Remotely Accessible', field: 'remote', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                ],
                Def26: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Incident / Case Number*', field: 'name', },
                { headerName: 'Complaint Type', field: 'type', },
                { headerName: 'Date of Occurance', field: 'occdate', flex: 1 },
                { headerName: 'Status', field: 'status', },
                { headerName: 'Employee Sanctions Required?', field: 'sanctions', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},           
                ],
                Def27: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Product Name*', field: 'name', },
                { headerName: 'Product Vendor', field: 'vendor', },
                { headerName: 'Product Function', field: 'function', },
                { headerName: 'Product Location', field: 'loc', flex: 1 },
                { headerName: 'Acquisition Date', field: 'adate', },
                { headerName: 'Risk Rating', field: 'risk', },
                { headerName: 'PHI / PII', field: 'phi', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},   
                { headerName: 'System Owner', field: 'owner', },                
                ],                 
                Def28: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Issue ID*', field: 'name', },
                { headerName: 'Summary', field: 'summary', flex: 1 },
                { headerName: 'Issue Key', field: 'key', },
                { headerName: 'Issue Type', field: 'type', },
                { headerName: 'Status', field: 'status', },
                { headerName: 'Project Name', field: 'pname', },
                { headerName: 'Project Type', field: 'ptype', },
                { headerName: 'Project Lead', field: 'plead', },
                { headerName: 'Priority', field: 'priority', },                
                ], 
                Def29: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Device Name*', field: 'name', },
                { headerName: 'Type of Change (Disposal, Transfer)', field: 'type', flex: 1},
                { headerName: 'Date', field: 'occdate', },      
                ],
                Def30: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Issue ID*', field: 'name', },
                { headerName: 'Description of Vulnerability', field: 'summary', },
                { headerName: 'Date Created', field: 'cdate', },
                { headerName: 'Remediation Priority', field: 'rpriority', },
                { headerName: 'Remediation Date', field: 'rdate', },
                { headerName: 'Remediation Actions', field: 'ractions',  flex: 1 },
                { headerName: 'Status', field: 'status', },             
                ],
                Def31: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Connection Name*', field: 'name', },
                { headerName: 'Originating System', field: 'origin', },
                { headerName: 'Destination System', field: 'dest', },
                { headerName: 'Connection Description', field: 'desc', flex: 1 }, 
                ],
                Def32: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Network Service Provider*', field: 'name', },
                { headerName: 'Service Location', field: 'loc', },
                { headerName: 'Service Provider Type', field: 'type', },
                { headerName: 'Service Description', field: 'desc', flex: 1 }, 
                { headerName: 'Risk Rating', field: 'risk', },
                ],
                Def33: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Name*', field: 'name', },
                { headerName: 'Status', field: 'status', },
                { headerName: 'IP Address', field: 'ip', },
                { headerName: 'AZ', field: 'az', flex: 1 }, 
                { headerName: 'Role', field: 'role', },
                { headerName: 'Environment', field: 'env', },
                { headerName: 'Function', field: 'func', },
                { headerName: 'Operating Systems', field: 'os', },
                ],
                Def34: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Ticket ID*', field: 'name', },
                { headerName: 'Access System Being Reset', field: 'system',  flex: 1 },
                { headerName: 'Request Date', field: 'rdate', },
                { headerName: 'Request Submitter', field: 'rsubmitter', },
                { headerName: 'Request Assignee', field: 'rassignee', },
                { headerName: 'Ticket Status', field: 'status', },             
                ],
                Def35: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Asset ID*', field: 'name', },
                { headerName: 'System Name', field: 'system',  flex: 1 },
                { headerName: 'Maintenance Summary', field: 'summary', },
                { headerName: 'Record Storage Location', field: 'loc', },
                { headerName: 'Service Provider Name', field: 'sname', flex: 1 }, 
                { headerName: 'Maintenance Contact Name', field: 'mname', },
                { headerName: 'Maintenance Contact Email', field: 'memail', },
                { headerName: 'Service Provider Facility Sign-In Time', field: 'stime', },
                ],
                Def36: [
                { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                { headerName: 'Incident ID*', field: 'name', },
                { headerName: 'Identification Date', field: 'idate', },
                { headerName: 'Incident Description', field: 'idesc', flex: 1 },
                { headerName: 'Details', field: 'details', },
                { headerName: 'Remediation Description', field: 'rdesc', },
                { headerName: 'Status', field: 'status', },             
                ],
                Def37: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def38: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def39: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def40: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def41: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def42: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def43: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def44: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def45: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def46: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def47: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def48: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def49: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def50: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def51: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def52: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def53: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def54: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def55: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def56: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def57: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def58: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def59: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def60: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def61: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def62: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def63: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def64: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def65: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def66: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def67: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def68: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def69: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def70: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def71: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def72: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},
                  { headerName: 'Physical Location', field: 'loc', },
                ],
                Def73: [
                  { headerName: 'Sample', field: 'selected', resizable: false, minWidth: 110, width: 110, cellRenderer: ListCheckbox, editable: false},
                  { headerName: 'Asset Name*', field: 'name', },
                  { headerName: 'Description / Function', field: 'desc', flex: 1},                  
                ],

            }
        },
        components: {
            AgGridVue,
            Confirmation,
            CustomLoadingOverlay,
            
        },
        methods: {
          test2(){


              console.log(this.SelectedInventory)


          },
          countSelected(){
            if(this.gridApi){
              let cnt = 0
              this.gridApi.forEachNode(function (node) {
                if( node.data.selected == true ){ cnt++ }
              });
              this.selectedRows = cnt
            }
            
          },
          genRandNums(max, quantity){
            if (quantity > max){ quantity = max} //if quantity is greater than row count it will get stuck in a loop
            var arr = [];
            while(arr.length < quantity){
                var r = Math.floor(Math.random() * max) + 1;
                if(arr.indexOf(r) === -1) arr.push(r);
            }
            return arr;
          },
          selectRandom(){
            let rowCnt = this.gridApi.getDisplayedRowCount()
            if (rowCnt <= 0){ return }

            let selRay = []

            if (rowCnt < 50 ){ selRay = this.genRandNums(rowCnt, 3)}
            if (rowCnt > 49 && rowCnt < 250 ){ selRay = this.genRandNums(rowCnt, (rowCnt * 0.1))}
            if (rowCnt >= 250 ){ selRay = this.genRandNums(rowCnt, 25)}

            console.log(rowCnt, selRay)

            this.gridApi.forEachNode(function (node) {
              
              let selstate = false
              if ( selRay.indexOf(node.rowIndex + 1) >= 0 ){ selstate = true }
              if ( selRay.indexOf(node.rowIndex + 1) < 0 ){ selstate = false }

              let rowNodeData = node.data
              if (selstate !== rowNodeData.selected ){ rowNodeData.selected = selstate; node.setData(rowNodeData)}             

            });

            this.valueChange = true
            this.InventoryData[this.SelectedInventory.id].rand = true
            this.countSelected()



            
          },
          async saveInv(){

            if (this.checkRequired() == false){ this.$store.commit('alertpop', { message: 'Please fill out all of the required fields.', type: "warning" }); return }

            const batch = writeBatch(db);
            let rowD = [];
            const InvNum = this.SelectedInventory.id

            this.gridApi.forEachNode(function (node) {

              rowD.push(node.data);

            });


            //process new objects and updates
            rowD.forEach((element) => {

              if ("uid" in element){
              //old object              
              let ref = doc(db, "projects/" + this.currentProject + "/inventory/", element.uid)
              batch.update(ref, element);

              } else {

              //new object
              let newdata = element
              newdata.inventory = InvNum
              newdata.creationDate = Timestamp.now()
              let ref = doc(collection(db, "projects/" + this.currentProject + "/inventory/"))
              batch.set(ref, newdata);

              }

            });

            //process deletions
            let deletions = []
            this.frowData.forEach((element1) => {
              //check the difference between old data and new data
              let found = false
              rowD.forEach((element2) => { if (element1.uid == element2.uid){ found = true} })
              if (found == false){ deletions.push(element1)}
            })

            deletions.forEach((dels) => {
              let ref = doc(db, "projects/" + this.currentProject + "/inventory/", dels.uid)
              batch.delete(ref);
            })

            //save inventory data
              let dataref = doc(db, "projects/" + this.currentProject + "/inventory/data")
              batch.set(dataref, this.InventoryData);

            //write batch job
            try {
              //write batch
              await batch.commit();
              this.$store.commit('alertpop', { message: 'Saved successfully.', type: "success" });
              this.valueChange = false
                } catch(e) {
                  this.$store.commit('alertpop', { message: 'An error occured, please try again.', type: "warning" });
                  console.error(e);
                  }

                  

          },
          confirmNav(){
            if(this.SelectedInventory == null){ return }
            console.log("confirm nav")
            if (this.valueChange == true){
              this.$refs.confdlg.open({title: "Data Changed", message: "Data in this inventory has been changed. Are you sure you want to navigate away?" }).then(result => {
                if (result == false){ this.SelectedInventory = this.currentInventory; return }
                if (result == true){ this.invChange() }
              });
            } else {
              this.invChange()
              console.log("inv change")
            }
          },
          async loadData(){

            const InvNum = this.SelectedInventory.id
            console.log("inv numb", InvNum)
            const q = query(collection(db, "projects/" + this.currentProject + "/inventory/"), where("inventory", "==", InvNum));
            const querySnapshot = await getDocs(q);
            let rowD = []
            querySnapshot.forEach((doc) => {

              let modData = doc.data()
              modData.uid = doc.id
              rowD.push(modData)              

            });
            this.rowData = rowD
            this.frowData = rowD

            //get inventory data
            const docRef = doc(db, "projects/" + this.currentProject + "/inventory/", "data");
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              console.log("Document data:", docSnap.data());

              Object.keys(docSnap.data()).forEach(key => {
                this.InventoryData[key] = docSnap.data()[key]
                //console.log(key, docSnap.data()[key]);
              });              

              //this.InventoryData = docSnap.data()
              this.setNAOverlay()
            } else {
              console.log("No inventory document!");
            }

            //value change reset
            this.valueChange = false
            this.countSelected()
            
          },
          onGridReady(params) {
              this.gridApi = params.api;
              this.columnApi = params.columnApi;
              //this.gridApi.setSuppressClipboardPaste(false);
              this.gridApi.setGridOption('suppressClipboardPaste', false)
              this.setNAOverlay()
              this.invChange()      
          },
          onRowSelected(event) {

          },
          onCellValueChanged(params) {
            this.valueChange = true
          },
          onRowDataUpdated(params) {
            console.log("row data updated:". params)
          },
          getSelectedRows() {

          },
          processDataFromClipboard(params) {
                const emptyLastRow = params.data[params.data.length - 1][0] === '' && params.data[params.data.length - 1].length === 1;

              if (emptyLastRow) {
                params.data.splice(params.data.length - 1, 1);
              }
              console.log("process clip data")
              const lastIndex = this.gridApi.getModel().rowsToDisplay.length - 1;
              const focusedCell = this.gridApi.getFocusedCell();
              const focusedIndex = focusedCell.rowIndex;

              if (focusedIndex + params.data.length - 1 > lastIndex) {
                const resultLastIndex = focusedIndex + (params.data.length - 1);
                const addRowCount = resultLastIndex - lastIndex;
                let rowsToAdd = [];
                let addedRows = 0;
                let currIndex = params.data.length - 1;
                while (addedRows < addRowCount) {
                  rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
                  addedRows++;
                  currIndex--;
                }
                rowsToAdd = rowsToAdd.reverse();
                let newRowData = [];
                rowsToAdd.map((r) => {
                  let row = {};
                  let currColumn = focusedCell.column;
                  r.map((i) => {
                    row[currColumn.colDef.field] = i;
                    currColumn = this.columnApi.getDisplayedColAfter(currColumn);
                  });
                  newRowData.push(row);
                });
                this.gridApi.applyTransaction({ add: newRowData });

              }
              return params.data;
          },
          invChange(){

            for (let i = 1; i <= 73; i++) {
                if (this.SelectedInventory.id == i) {
                    this.columnDefs = this['Def' + i];
                }
              }

            this.loadData()
            this.currentInventory = this.SelectedInventory
            
          },
          addEmptyRow() {
            var newItem = {};
            this.gridApi.applyTransaction({add: [newItem]});
          },
          remSelectedRows(){
            const selectedRows = this.gridApi.getSelectedRows();
            this.gridApi.applyTransaction({ remove: selectedRows });

          },
          checkRequired(){
            let found = true
            this.gridApi.forEachNode(function (node) {
              if (!("name" in node.data)){ found = false}
              if("name" in node.data && node.data.name.trim().length == 0){found = false}
            });

            if (found == false){ return false }
            if (found == true){ return true }

          },
          setNAOverlay(){
            if( this.InventoryData[this.SelectedInventory.id]?.na == true ){ this.gridApi.showLoadingOverlay() } else { this.gridApi.hideOverlay() }
          },

        },
        beforeCreate(){

        },
        created(){
          this.loadingOverlayComponent = 'CustomLoadingOverlay';
          this.loadingOverlayComponentParams = {
            loadingMessage: 'This inventory has been marked as N/A...',
          };          

          for (let i = 1; i <= 73; i++) {
            this.InventoryData[i] = {na: false, complete: false};
          }
        },
        beforeMount() {

          if (this.$store.getters.loaded == false){ this.$store.commit('beginLoading') }
          //get the current project id from the URL
          this.currentProject = this.$route.query.project

          //if project is already in store then load data to rows, if not then load database and wait for watcher
          if (this.currentProject in this.$store.state.loadedProjects && this.$store.state.loadedProjects[this.currentProject] == true ) {

            if (this.$store.state.initializing.projects == true){ document.title = "Vantage - Dashboard - " + this.$store.state.projMasterObj[this.currentProject].name }
            
            } else {
            this.$store.dispatch('loadProject', { projid: this.currentProject })
            }
          //set component parent for checkbox
          this.context = {componentParent: this};
        },

        mounted() {

          //this.SelectedInventory = "01: Servers"
          //this.invChange()          
          
        },
        computed: {
          rowCount(){
            if(this.gridApi){return this.gridApi.getDisplayedRowCount();}
            return 0
          },

          projName(){
            return this.$store.state.projMasterObj[this.currentProject].name
          },      


        },
        watch: { //watch for database changes and update the list

          "$store.state.initializing.projects"(nv) {
            console.log("project change", nv)
            if (nv == true){
              document.title = "Vantage - " + this.$store.state.projMasterObj[this.currentProject].name
             }

          },
          "$store.state.loadedProjects": {
            handler: function (val, oldVal) {
              //if new project is loaded check if its set to true and then load the row data
              if (this.currentProject in val && val[this.currentProject] == true){ 

               if (this.$store.state.initializing.projects == true){ document.title = "Vantage - Inventory - " + this.$store.state.projMasterObj[this.currentProject].name }

                }
      
            },
            deep: true
          }
        }

    }
</script>

<style>

.no-active {
  color: rgba(255, 255, 255, 0.7) !important
}


.ag-theme-alpine div.ag-row {
    font-size: 13px !important;
}

.ag-header-cell-label {
    font-family: "Roboto", sans-serif !important;
    text-transform: uppercase !important;
    font-size: 0.75rem !important;
    font-weight: 500;
    letter-spacing: 0.0892857143em !important;
}

#ev-list-bar {
    display: flex;
    flex-wrap: nowrap;
    background-color: #3d4856;
    white-space: nowrap;
    overflow: hidden;
    height: 48px;

}

.whole-table {
    justify-content: center;
    width: 95%;
    
    height: 94%;    
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.main-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
} 

</style>
